.Container {
    margin: 20px 20%;
    height: 150px;
    padding: 1.2rem;
    width: auto;
    text-align: center;
    box-shadow: 3px 3px 5px 6px #42466a33;;
}

.GenerateButton {
    margin: 10px;
    background-color: #7ad47a;
}

.RemoveButton {
    margin: 10px;
    background-color: rosybrown;
}