.main-page {
  display: flex;
}

.content-left {
  width: 80%;
  padding: 20px;
}



.message {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
}



/* Add styles for the text input field */
.message-input {
  width: calc(100% - 80px); /* Adjust width as needed */
  padding: 10px;
  margin-top: 10px;
  margin-right: 2px;
}

/* Add styles for the send button */

/* Styles for the chat window */
.chat-window {
  width: 50%; /* Adjust width as needed */
  height: 70vh; /* Adjust height as needed */
  /* background-color: #8ff0f3;  */
  border: 2px solid #20c997;
  /* padding: 10px; */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-right: 50px;
  margin-top: 20px;
  margin-bottom: 10px;
  border-radius: 15px; /* Border radius for curved edges */
  box-shadow: 4 6px 8px #20c997; /* Box shadow for a card-like effect */
  overflow: hidden; /* Hide overflow content */
}

/* Styles for the chat messages */
.chat-messages {
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
  overflow-wrap: break-word;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}
.chat-messages::-webkit-scrollbar {
  display: none; /* WebKit */
}
.message-container {
  display: flex;
  align-items: flex-start; /* Align items to the top of the container */
  justify-content: flex-end; /* Align user messages to the right */
}

.message-container.user-message .message-content {
  background-color:#007bff;
  color: #fff;
  border-radius: 15px 15px 15px 0;
}



.avatar-container {
  margin-right: 10px; /* Add margin between avatar and message content */
}

.message-container.bot-message .message-content {
  background-color: #20c997;
  color: #fff;
  border-radius: 15px 15px 0 15px; 
}

.message-content {
  padding: 10px;
  border-radius: 5px;
  margin-right: 10px;
  max-width: 70%; /* Limit the width of the message content */
}

/* Styles for individual messages */
.message {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
}

.user-message {
  /* background-color: #6587d1; Example background color for user messages */
  color: #fff;
  padding: 10px;
}

.bot-message {
  /* background-color: #28a745; Example background color for bot messages */
  padding: 10px;
  color: #fff;
}

/* Styles for the text input field */
.message-input {
  width: calc(100% - 80px); /* Adjust width as needed */
  padding: 10px;
  margin-top: 10px;
  margin-left: 20px;
}

/* Styles for the send button */
.send-button {
  /* Example button color */
  color: #007bff;
  background-color: transparent;
  border: none;
  padding: 10px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  /* font-size: 4px; */
  
  cursor: pointer;
  border-radius: 8px; /* Add border-radius for curved edges */
}
@keyframes shake {
  0% { transform: translateX(0); }
  25% { transform: translateX(-5px); }
  50% { transform: translateX(5px); }
  75% { transform: translateX(-5px); }
  100% { transform: translateX(0); }
}

.message-container.empty {
  animation-name: shake; /* Apply the 'shake' animation */
}