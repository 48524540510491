@charset "UTF-8";
/* CSS Document */


@font-face {
	font-family: 'Helvetica-Regular';
	src: url('../src/assets/fonts/Helvetica-Regular.eot');
	src: local('☺'), url('../src/assets/fonts/Helvetica-Regular.woff') format('woff'), url('../src/assets/fonts/Helvetica-Regular.ttf') format('truetype'), url('../src/assets/fonts/Helvetica-Regular.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
    font-family: 'helvetica bold';
    /* src: url(../fonts/Font/Helvetica-Bold.ttf); */
    src: url(./assets/fonts/Helvetica-Bold.ttf);
    font-weight: bold;
} 

@font-face {
    font-family: 'helvetica bolder';
    /* src: url(../fonts/Font/Helvetica-Black.otf); */
    src: url(./assets//fonts//Helvetica-Black.otf);
    font-weight: bolder;
}
*{
    margin:0;
    padding:0;
    box-sizing: border-box;
    font-size:13px;
}
.font{
    font-size: 16px;
}
#root{
    height:100vh;
}
body {
    margin: 0;
   font-family: 'helvetica';
    background-color: #fff;
    height:100%;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
}

/*=========================================== Top Navbar start =========================================*/

.topNavigation{
height:17vh;
padding:20px;
}

/*=========================================== Top Navbar end =============================================*/

/*============================ general body container style start ==========================*/
.app-content {
    margin-left: 1px;
    padding: 40px 70px;
    position: relative;
    height: 83vh;	
    transition: all 0.3s;
    overflow-x: scroll;
}
.app-content::-webkit-scrollbar {
    width:1px;
    height:0px;
    display: none;
  }
   
.app-content::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
   
.app-content::-webkit-scrollbar-thumb {
    background-color: #003246;
    outline: 1px solid #003246;
  }
.container1{
	min-width: 50px;
}

/*=================================== general body container style end =========================*/

/*================================= Login Form style start =====================================*/
.loginImage{
    justify-content: center;
}


/*================================== Login Form style end ======================================*/
/*================================== Change Password Start =====================================*/

.changePassContent input::-ms-reveal,
input::-ms-clear {
  display: none;
}
.passErrMsg{
    color:#9e0613;
}
.changePassword .addOnBtns{
    border-radius: 0px;
    border-color: rgb(207, 207, 207);
    border-left:1px solid white;
    background-color: #fff;
    color: grey;
    visibility: visible;
}

.changePassword{
    min-width:300px;
    max-width: 640px;
    min-height:400px;
    font-size: 12px;
    border:1px solid transparent;
    border-radius: 0px;
    padding:30px;
    margin: auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.changePassContent label.form-label{
    font-size: 12px;
}
.changePassContent .form-control{
    padding:10px;
    border-radius: 0px;
}
.changePassContent .form-control .eyeIcon{
    position: absolute;
    transform: translateX(-50%);

}
#changePassBtn{
    border:1px solid transparent;
    border-radius: 0px;
    background: #034439;
    width:100px;
    margin:auto;
}
.changePassBtnContainer{
    display: inline-block;
    width:100%;
    align-items: center;
}

/*================================== Change Password End ========================================*/
.NuOcrfile{
	padding: 0px 0px;
	margin: 0px 0px; 
	/* font-family: 'Open Sans', sans-serif; */
    font-family:  'helvetica';
	font-size: 10px; 
	color: #094067;
	width: 100%;
	border: none;
}

.uploadbtndiv img{

	width: 50px;
}

.maincontentdiv{
	width: 100%;
	padding: 0px;
	margin: 0;
	
}


.previewmaindiv{
	padding: 20px 0px 0px 20px;
	margin: 0px;
	background-image: url("images/preview-bg.png");
	background-repeat: no-repeat;
	background-position:left;
	background-size: contain;
	width: 100%;
}

.startrecogbtn{
    padding: 0px 0px;
    margin: 0px 0px 0px 0px;
    /* background-color: #fff; */
    /* font-family: 'Open Sans', sans-serif; */
    font-family: 'helvetica';
    font-size: .8rem;
    color: #094067;text-align: left;
	
    border-radius: 70px;
	min-width: 200px;
	/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ff8c3d+0,ff5969+100 */
	background: #fff; /* Old browsers */
	/*float: right;*/
	border: 1px solid #3da9fc;
	-webkit-box-shadow: 0px 0px 5px -2px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 0px 5px -2px rgba(0,0,0,0.75);
box-shadow: 0px 0px 5px -2px rgba(0,0,0,0.75);

}

.previewimgload{
	padding: 0px;
	margin: 0px;
	width: 100%;
	height: 300px;
	background-image: url("images/bg-img.png");
	background-position: center;
	background-size: cover;
}

.preLoadimg{
	padding: 10px;
	margin: 0px;
	width: 100%;
	height: 300px;
	/* background-image: url("images/bg-img.png"); */
	background-color:#fff;
	background-position: center;
	/* background-size: cover; */
	/* border-width: 1px; */
	/* color: #fff; */
}

.Loadedimg{
	padding: 0px;
	margin: 0px;
	width: 100%;
	height: 300px;
	/* background-image: url("images/bg-img.png"); */
	background-color:#fff;
	background-position: center;
	/* background-size: cover; */
	/* border-width: 1px; */
	/* color: #fff; */
}

.previewimgload img{
	padding: 0px;
	margin: 0px;
	width: 100%;
	object-fit:contain;
	
	
}

.previewimgloaddiv{
	padding: 0px;
	margin: 0px;
	width: 100%;
	max-height: 300px;
	overflow: auto;
	
}

.searchinput{
	padding: 5px 10px;
	margin: 0px 0px; 
	/* font-family: 'Open Sans', sans-serif; */
    font-family: 'helvetica';
	font-size: 15px; 
	color: #094067;
	width: 90%;
	border: none;
}

.selctempbtndiv{
	width: 100%;
	overflow: auto;
	padding: 5px;
	margin: 0px 0px;
}
/* Change password */
.submitbutton{
    /* padding: 6px 35px;
    margin: 2px 2px 2px 0;
    background-color: black;
    font-family: "Lato",sans-serif;
    font-size: 13px;
    color: white;
    text-align: center;
    border-radius: 10px;
    min-width: 150px;
    background:black;
    border: 1px solid black;
	float: right; */ 
    /* position: center; */
    position: center;
    height: 30px;
    width: 140px;
    padding-top: 4px;
    background: #003246;
    font-size: 13px;
    font-family: helvetica;
    color: white;
    margin-top: 0px;
    padding-bottom: 4px;
  
    
   
}
.btndiv{
    padding-bottom: 4px;
    padding-left: 25%;
}
.errorMsg{
    color: red;
}
.jobinputdiv{
    padding: 5px;
    margin: 0;
    border: 1px solid #e8e9ec;
    border-radius: 3px;
    background-color: #fff;
    border-color: black;
}

.jobinputtext{
    padding: 0;
    margin: 0;
    font-family: "Lato",sans-serif;
    font-size: 13px;
    color: #373737;
    background-color: transparent;
    border: black;
    width: 98%;
    border-color: black;
}
.page-content{
    align-items: center;
    margin: auto;
    padding-left: 25%;

}
/* .page-content-wrapper{
    padding-left: 25%;
} */
.subheader{
    padding-left: 25%;
}

.jobsort{
    padding: 0;
    margin: auto;
    background-color: #fff;
    box-shadow: -2px 8px 32px -31px rgb(0 0 0 / 75%);
    overflow: auto;
    padding-left: 25%;
    padding-bottom: 25px;
}
.addjobdiv{
    padding: 15px;
    margin: 0;
} 
.changepasswordmust{
    font-size: 12px;
    color: green;

    padding-top: 5px;
   
    padding-left: 5px;
    padding-right: 5px;
}
.joblabel:after {
    content:" *";
    color: red;
  }
.joblabel{
    padding-top: 30px;
}

.selecttemplatebtn{
    padding: 3px 40px;
    margin: 0px 0px 0px 0px;
    /* background-color: #3da9fc; */
    /* font-family: 'Open Sans', sans-serif; */
    font-family: 'helvetica';
    font-size: .9rem;
    color: #fff;text-align: center;
	border: none;
    border-radius: 3px;
	min-width: 200px;
	/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ff8c3d+0,ff5969+100 */
	background: #3da9fc; /* Old browsers */
	float: right;

}

.searchmaindiv{
	padding: 5px;
	margin: 0px;
	background-color: #fff;
	border: 1px solid #3da9fc;
	width: 100%;
	border-radius: 5px;
}

.searchbtn{
    padding: 5px 10px;
  margin: 0px 0px 0px 0px;
  /* background-color: transparent; */
  color: #fff;
  text-align: center;
  border: none;
  border-radius: 3px;
  background: transparent;
  float: right;
}

.notifytext{
	padding: 0px 0px;
	margin: 0px 0px 10px 0px; 
	/* font-family: 'Open Sans', sans-serif; */
    font-family: 'helvetica';
	font-size: 12px; 
	color: #094067;
}

.tabset > input[type="radio"] {
    position: absolute;
    left: -200vw;
  }

  .tabset > input[type="radio"] {
    position: absolute;
    left: -200vw;
  }
  
  .tabset .tab-panel {
    display: none;
  }
  
  .tabset > input:first-child:checked ~ .tab-panels > .tab-panel:first-child,
  .tabset > input:nth-child(3):checked ~ .tab-panels > .tab-panel:nth-child(2),
  .tabset > input:nth-child(5):checked ~ .tab-panels > .tab-panel:nth-child(3),
  .tabset > input:nth-child(7):checked ~ .tab-panels > .tab-panel:nth-child(4),
  .tabset > input:nth-child(9):checked ~ .tab-panels > .tab-panel:nth-child(5),
  .tabset > input:nth-child(11):checked ~ .tab-panels > .tab-panel:nth-child(6) {
    display: block;
  }
  
  /*
   Styling
  */
  
  
  .tabset > label {
    position: relative;
    display: inline-block;
    padding: 15px 15px 25px;
    border: 1px solid transparent;
    border-bottom: 0;
    cursor: pointer;
    font-weight: 600;
  }
  
  .tabset > label::after {
    content: "";
    position: absolute;
    left: 15px;
    bottom: 10px;
    width: 22px;
    height: 4px;
    background: #8d8d8d;
  }
  
  .tabset > label:hover,
  .tabset > input:focus + label {
    color: #06c;
  }
  
  .tabset > label:hover::after,
  .tabset > input:focus + label::after,
  .tabset > input:checked + label::after {
    background: #06c;
  }
  
  .tabset > input:checked + label {
    border-color: #ccc;
    border-bottom: 1px solid #fff;
    margin-bottom: -1px;
  }
  
  .tab-panel {
    padding: 30px 0;
    border-top: 1px solid #ccc;
  }

  .industrymaindiv{
	padding: 0px;
	margin: 0px 0px 0px 0px;
}

.industrylabel{
	padding: 5px 0px;
	margin: 0px 0px; 
	/* font-family: 'Open Sans', sans-serif; */
    font-family: 'helvetica';
	font-size: 14px; 
	color: #094067;
}

.industrydiv{
	padding: 5px;
	margin: 0px;
	background-color: #fff;
	border: 1px solid #3da9fc;
	width: 100%;
	border-radius: 5px;
}

.industryselect{
	padding: 5px 10px;
	margin: 0px 0px; 
	/* font-family: 'Open Sans', sans-serif; */
    font-family: 'helvetica';
	font-size: 15px; 
	color: #094067;
	width: 100%;
	border: none;
	background-color: transparent;
}

.app-header {
    border: none;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    padding: 0;
    z-index: 1030;
    height: 60px;
    background: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: space-between;
    -ms-flex-pack: space-between;
    justify-content: space-between;
    -webkit-transition: box-shadow .2s linear;
    -moz-transition: box-shadow .2s linear;
    -ms-transition: box-shadow .2s linear;
    -o-transition: box-shadow .2s linear;
    transition: box-shadow .2s linear;
    -webkit-box-shadow: 0 6px 6px rgb(31 107 255 / 10%);
    box-shadow: 0 6px 6px rgb(31 107 255 / 10%);
}

.app-header .brand {
    width: 240px;
    height: 60px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
}



.app-header .desktop-toggler {
    height: 60px;
    width: 4.0625rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.app-header .menu-toggler {
    position: relative;
    border: none;
    background: 0 0;
    width: 45px;
    display: block;
    outline: 0;
    padding: 0 10px;
    margin: 0;
    height: 60px;
}

.app-header .desktop-toggler .menu-toggler {
    width: 65px;
    height: 60px;
    padding: 0 20px;
}

.app-header .menu-toggler .bar {
    display: block;
    background: #212837;
    height: 2px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
}

.app-header .menu-toggler .bar+.bar {
    margin-top: 4px;
}

.app-header .brand .brand-logo {
    font-weight: 700;
    color: #212837;
    font-size: 20px;
    text-decoration: none;
    height: 60px;
    width: 100%;
    padding: 20px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
}

.app-header .menu {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
}

.dropdown, .dropend, .dropstart, .dropup {
    position: relative;
}

.app-header .menu .menu-item {
    position: relative;
}

.app-header .menu .menu-item .menu-link {
    height: 60px;
    padding: 20px;
    text-decoration: none;
    color: #212837;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
}

.app-header .menu .menu-item .menu-icon {
    font-size: 20px;
}

.app-header .menu .menu-item .menu-label {
    position: absolute;
    top: 10px;
    right: 10px;
    background: #1f6bff;
    color: #fff;
    font-weight: 700;
    font-size: 12px;
    padding: 0 6px;
    min-width: 20px;
    height: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-border-radius: 30px;
    border-radius: 30px;
}

.app-header .menu .menu-item .menu-img, .app-header .menu .menu-item .menu-img-text {
    height: 36px;
    width: 36px;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}


.rounded-circle {
    border-radius: 50%!important;
}

.templatemaindiv{
	min-width: 1000px;
	/* overflow: auto; */
}

.tempcard{
	width: 100%;
	position: relative;
    cursor: pointer;
}

.temdesc {
    padding: 15px;
    text-align: center;
	font-size: .9rem;
    color: #3c4e71;
    font-weight: 400;
}

.templatedots{
	font-size: .8rem;
    color: #3c4e71;
    font-weight: 400;
	position: absolute;
	z-index: 1001;
	top: 2%;
	right: 3%;
}
 .btn.more-btn.dropdown-toggle {
    position: absolute !important;
} 

.editprofileoption{
	padding: 2px 10px 15px 10px;
	margin: 0;
	position: absolute;
	background-color: #ffffffed;
    z-index: 1020;
    top: 29px;
    right: 0;
    width: 120px;
	border-radius: 5px;
	box-shadow: 2px 2px 7px -3px rgba(0,0,0,0.75);
-webkit-box-shadow: 2px 2px 7px -3px rgba(0,0,0,0.75);
-moz-box-shadow: 2px 2px 7px -3px rgba(0,0,0,0.75);
}


.profileclosebnt{
	
	color: #000;
	padding: 0px;
	margin: 0px;
	text-align: right;
}

.profileeditdiv{
	padding: 0px;
	margin: 0px;
}

.profileeditdiv ul {
	padding: 0px;
	margin: 0px;
	display: flex;
	flex-direction: column;
}

.profileeditdiv ul li{
	padding: 8px 5px 3px 5px;
	margin: 0px;
	
	color: #000;
	flex: 1;
	border-bottom: 1px solid #333;
	text-align: left;
	font-size: .8rem;
	list-style: none;
}

.profileeditdiv ul li span{
	padding: 0px 5px 0px 0px;
	margin: 0px;
	
}

.profileeditdiv ul li a{
	
	color: #000;
	
}

.profileeditdiv ul li a:hover{
	text-decoration: none;
	color: #000;
	
}


.card-body {
    flex: 1 1 auto;
    padding: 1rem 1rem;
}

.mh-100 {
    max-height: 100%!important;
}

.mw-100 {
    max-width: 100%!important;
}

.app-header .menu .menu-item .menu-img+.menu-text, .app-header .menu .menu-item .menu-img-text+.menu-text {
    margin-left: 8px;
}

.app-header .menu .menu-search {
    margin: 0 auto 0 50px;
    position: relative;
    max-width: 760px;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.dropdown-menu.show {
    display: block;
    position: absolute !important;
    top: 37px!important;
    display: inline!important;
    
}

.dropdown-item {
    white-space: normal!important;
    color: black!important;
    font-size: 14px;
    padding: .5rem 1.5rem!important;

}

.dropdown-menu-end[data-bs-popper] {
    right: 0;
    left: auto;
}

.dropdown-menu[data-bs-popper] {
    left: 0;
    margin-top: .125rem;
    top: 37px!important;
    display: inline!important;
    padding : 2rem
}

.dropdown-menu {
    -webkit-box-shadow: 0 8px 16px rgb(134 154 192 / 45%);
    box-shadow: 0 8px 16px rgb(134 154 192 / 45%);
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    z-index: 1000;
    display: none;
    min-width: 11rem;
    padding: .5rem 0;
    margin: 0;
    font-size: .875rem;
    color: #212837;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 0 solid rgba(0,0,0,.15);
    border-radius: 6px;
} 


.align-items-center {
    align-items: center!important;
}

.d-flex {
    display: flex!important;
}

.app-sidebar-minified .app-sidebar {
    width: 65px;
}

.app-sidebar {
    position: fixed;
    top: 60px;
    left: 0;
    bottom: 0;
    width: 240px;
    border-right: 1px solid #dae0ec;
    /* z-index: 1020; */
    display: -webkit-box;
    display: -ms-flexbox;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    transition: all 0.5s;
	display: none;
}

.app-sidebar-open {
    display: flex !important;
}

.app-sidebar .app-sidebar-content {
    padding: 10px 0;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.app-sidebar .menu {
    min-height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.app-sidebar-minified .app-sidebar .menu-header {
    margin-left: 5px;
    margin-right: 5px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.app-sidebar .menu .menu-header {
    margin: 8px 20px;
    font-size: .7875rem;
    font-weight: 600;
    color: #657eae;
}

.app-sidebar-minified .app-sidebar .menu-item {
    position: relative;
    padding: 3px 12px;
}

.app-sidebar .menu .menu-item {
    padding: 0 10px;
}

.app-sidebar .menu .menu-item.active:not(.has-sub)>.menu-link .menu-icon {
    color: #1f6bff;
}

.app-sidebar-minified .app-sidebar .menu-item .menu-link .menu-icon {
    margin-left: auto;
    margin-right: auto;
}

.dropdown {
    position: relative;
    display: inline-block;
  }

.app-sidebar .menu .menu-item .menu-icon {
    width: 1.25rem;
    height: 1.25rem;
    font-size: 1rem;
    color: #3c4e71;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.app-sidebar .menu .menu-item .menu-icon+.menu-text {
    margin-left: .625rem;
}

.app-sidebar .menu .menu-item .menu-text {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
}
.app-sidebar-minified .app-sidebar .menu-submenu, .app-sidebar-minified .app-sidebar .menu-text, .app-sidebar-minified .app-sidebar .slimScrollBar {
    display: none!important;
}

.app-sidebar .menu .menu-item.active:not(.has-sub)>.menu-link {
    color: #1f6bff;
    font-weight: 600;
    background: 0 0;
}


.appsidebarmenu{
	padding: 0px;
	margin: 0px;
}

.appsidebarmenu ul {
	padding: 0px;
	margin: 0px;
}

.appsidebarmenu ul li{
	padding: 0px;
	margin: 15px;
	font-family: 'helvetica';
    font-size: .9rem;
	font-weight: normal;
    color: #000;text-align: left;
	display: block;
}

.appsidebarmenu ul li img{
	width: 20px;margin: 0px 5px 0px 0px;
}


.Sspace{
	padding: 10px;
	
}
.Sspace2{
	/* padding: 100px; */
    margin-left:130px;
    padding: 100px ;
	
}
.Mspace{
	padding: 20px;
    padding-top: 6px;
	
}

.Lspace{
	padding: 30px;
	
}

.app-sidebar-minified .app-content {
    margin-left: 65px;
}

.page-header {
    color: #212837;
    padding: 0;
    border: none; 
}
.page-header h3{
    font-weight: bolder;
}
.page-header h4{
    font-weight: bolder;
}
.page-header .small, .page-header small {
    font-size: 12px;
    color: #3c4e71;
    font-weight: 400;
}


.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(101,126,174,.25);
    border-radius: 9px;
}


.cardimg {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
	background-color: #1361ff;
    background-image: url("../src/images/wave-bg.png");
    background-repeat: no-repeat;
	min-height: 250px;
	background-size: cover;
	background-position: bottom;
	border: none;
	margin: 0px 0px 10px 0px;
}


.card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1rem;
    border-radius: 8px;
}

.Dashhead{
	color: #fff;
    padding: 0px;
    border: none;
    margin: 0 0 1rem;
    font-size: 1rem;
    font-weight: 600;
}

.searchcategory{
	padding: 0px;
	margin: 0px;
}

.searchcategory ul{
	padding: 0px;
	margin: 0px;
}


.searchcategory ul li{
	padding: 0px ;
	margin: 10px 20px 10px 0px;
	display: inline-block;
	font-size: .9rem;
	color: #fff;
}


.textboxdiv{
	width: 70%;
	padding: 7px 10px;
	margin: 10px 0px 10px 0px;
	background-color: #fff;
	border: 1px solid #d9d8d8;
	border-radius: 5px;
}


.textbox{
	/* font-family: 'Source Sans Pro', sans-serif; */
    font-family: 'helvetica' ;
	color: #000;
	font-size: 1rem;
	padding: 2px 0px;
	margin: 0px;
	text-align: left; 
	width: 100%;
	border: none;
	background-color: transparent;
}


input:focus{outline: none;}
select:focus{outline: none;}
input[type="button"]{
   outline:none;
}
input[type="button"]::-moz-focus-inner {
   border: 0;
}
*:focus {
    outline: 0 !important;
}

.dashboardbtn{
	padding: 11px 25px;
    margin: 2px auto;
    background-color: #e18612;
    font-family:'helvetica' ;
    font-size: .9rem;
	font-weight: normal;
    color: #fff;text-align: center;
    border-radius: 3px;
	background:#1361ff;
	width: 25%;
	border: 1px solid #fff;
	
}

.btnmaindiv{
	width: 80%;
	margin: 0px;
	padding: 0px 0px 0px 0px;

}

.btn-yellow {
    color: #000;
    background-color: #fc0;
    border-color: #fc0;
	min-width: 100px;
}

.btn-yellow1 {
    color: #000;
    background-color: #fc0;
    border-color: #fc0;
	min-width: 100px;
}

.cardsubdiv{
	padding: 20px 20px;
}

.searchdashboardimg{
	width: 30%;
	position: absolute;
	right: 0px;
	bottom: 0px;
}

.searchdashboardimg img{
	width: 100%;
	
}

.searchdashboardimg1{
	width: 20%;
	position: absolute;
	right: 10px;
	bottom: 10px;
}

.searchdashboardimg1 img{
	width: 90%;
	
}

.card1 {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(101,126,174,.25);
    border-radius: 9px;
}


.cardimg1 {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
	background-color: #1361ff;
    background-image: url("../src/images/bg1.png");
	background-repeat: no-repeat;
	min-height: 180px;
	background-size: cover;
	background-position: bottom;
	border: none;
}

.dashpagevalue{
	color: #fff;
    padding: 0px;
    border: none;
    margin: 0 0 1rem;
    font-size: 2rem;
    font-weight: 600;
}

.card2 {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(101,126,174,.25);
    border-radius: 9px;
}


.cardimg2 {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
	background-color: #1361ff;
    background-image: url("../src/images/bg2.png");
	background-repeat: no-repeat;
	min-height: 180px;
	background-size: cover;
	background-position: bottom;
	border: none;
}

.dashbaordgriddiv{
	padding: 0px;
	margin: 10px 0px;
	min-width: 100%;
	overflow: auto;
}

.dashbaordgriddiv2{
	padding: 0px;
	margin: 10px 0px;
	min-width: 100%;
	overflow: auto;
    border: 1px solid #b9b8b8;
}

.tablehead {
	padding: 10px 4px;
	margin: 0px;
    font-size: 13px!important;
	color: #070000;
	border-bottom: 1px solid #eaeef3;
	background-color: #fff;
	text-align: center;
    /* font-family: "Open Sans",sans-serif; */
    font-family: 'helvetica';
}


.tabledesc {
	padding: 10px 4px;
	margin: 0px;
    font-size: 13px!important;
	color: rgb(10, 10, 10);
	border-bottom: 1px solid #eaeef3;
	background-color: #fff;
	text-align: center;
    /* font-family: "Open Sans",sans-serif; */
    font-family: 'helvetica';
}

.templatecard {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    background-color: #fff;
    border:1px solid #b9b8b8;
    min-height: 200px;
   border-radius: 5px;
}

.templatecardhead {
    padding: 15px;
    text-align: left;
	font-size: .8rem;
    color: #3c4e71;
    font-weight: 600;
}

.templatecardvalue {
    padding: 0px 15px 0px 15px;
	font-size: 1.2rem;
    color: #386df6;
    font-weight: 600;
}
.templatecardvalue1 {
    padding: 0px 15px 0px 15px;
	font-size: 1.2rem;
    color: #ee7d5f;
    font-weight: 600;
}
.templatecardvalue2 {
    padding: 0px 15px 10px 15px;
	font-size: 1.2rem;
    color: #386df6;
    font-weight: 600;
}

.templatecardvaluedesc {
    padding: 0px 15px;
    text-align: left;
	font-size: .8rem;
    color: #3c4e71;
    font-weight: 300;
}


/* .divider{
	padding: 0px;
	height: 1px;
	margin: 0px 20px;
	background-color: #eaeef3;
} */

.templategraph {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    background-color: #fff;
    border:1px solid #b9b8b8;
    min-height: 400px;
   border-radius: 5px;
}

/* 30 april */
.tabledesc-select {
	padding: 10px 4px;
	margin: 0px;
    font-size: 13px!important;
	color: #000;
	border-bottom: 1px solid #eaeef3;
	background-color: #fddea4;
	text-align: center;
}

.btn-cancel {
    color: #000;
    background-color: #e0e0e0;
    border-color: #696760;
	min-width: 100px;
}


.addusermaindiv{
	padding: 10px 0px;
	margin: 0px;
	color: #747475;
}


/* 5 May */

.userinputdiv{
	padding: 0px;
	margin: 0px;
}


.nuocrinputText{
	width: 100%;
	outline: none;
	border:none;
	border-bottom: 1px solid #a2a5ab;
 	box-shadow: none !important;
	/* font-family: 'Source Sans Pro', sans-serif; */
    font-family: 'helvetica';
	color: #000;
	padding: 5px 10px;
}

.nuocrinputText1{
	width: 100%;
	outline: none;
	border:none;
	border-bottom: 1px solid #f3f3f3;
 	box-shadow: none !important;
	/* font-family: 'Source Sans Pro', sans-serif; */
    font-family: 'helvetica';
	color: #000;
	padding: 5px 10px;
	background-color: #f3f3f3;
}


.userlabel{
	font-size: 1rem;
	/* font-family: 'Source Sans Pro', sans-serif; */
    font-family: 'helvetica';
	color: #747475;
	padding: 0px;
	margin: 0px 0px 5px 0px; 
}

.radiolabel{
	font-size: .9rem;
	/* font-family: 'Source Sans Pro', sans-serif; */
    font-family: 'helvetica';
	color: #000;
	padding: 0px;
	margin: 0px 0px 0px 0px; 
}


.backgroundblur{
	padding: 0px;
	margin: 0px;
	 -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(5px);
    background-color: rgba(255, 255, 255, 0.5); 
	height: 100vh;
	position: absolute;
	z-index: 1050;
	width: 100%;
	top:0px;
}

.confirmaitonmaindiv{
	position: absolute;
	top:20%;
	background-color: #fff;
	border-radius: 10px;
	padding: 20px 30px;
	margin: 0px auto;
	width: 500px;
	left: 0;
	right: 0;
	box-shadow: 2px 2px 7px -3px rgba(0,0,0,0.75);
-webkit-box-shadow: 2px 2px 7px -3px rgba(0,0,0,0.75);
-moz-box-shadow: 2px 2px 7px -3px rgba(0,0,0,0.75);
}

.confirmationhead{
	
	color: #000;
	font-size: 1rem;
	padding: 0px;
	margin: 0px;
	text-align: center;font-weight: 600;
	border-bottom: 1px solid #a9a4a4;
}


.filtermaindiv{
	/*border-top: 1px solid #ababab;
	border-bottom: 1px solid #ababab;*/
	padding: 0px 10px;
	margin: 0px 0px;
	background-color: #fff;
}
.addtemplatediv{
	padding: 0px;
	margin: 0px 0px 10px 0px;
}
.btn-yellow {
    color: #000;
    background-color: #fc0;
    border-color: #fc0;
	min-width: 100px;
}

/* .menu-search .menu-search-input .form-control {
    background: #fff;
    border-color: transparent;
    padding-left: 46px;
    height: 40px;
    font-size: 1rem;
	
} */
.form-control {
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: .875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #3c4e71;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #c9d2e3;
    appearance: none;
    border-radius: 6px;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}


.menu-search-icon {
    position: absolute;
    left: 0;
    width: 46px;
    top: 0;
    bottom: 0;
    font-size: 16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
.menu-search {
    margin: 0 auto 20px 0px;
    position: relative;
    max-width: 760px;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
	    background-color: #fff;
}
.tembtn {
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    color: #212837;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: .875rem;
    border-radius: 6px;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.card-body {
    flex: 1 1 auto;
    padding: 1rem 1rem;
}


.tempcard img{
	width: 100%;
	height: auto;
}

.temdesc {
    padding: 15px;
    text-align: center;
	font-size: .9rem;
    color: #3c4e71;
    font-weight: 400;
}

.temhover{
	 text-align: center;
	font-size: .8rem;
    color: #3c4e71;
    font-weight: 400;
	background-color: #000;
	z-index: 1000;
	position: absolute;
	padding: 10px 5px;
}

.templatedots{
	font-size: .8rem;
    color: #3c4e71;
    font-weight: 400;
	position: absolute;
	z-index: 1001;
	top: 2%;
	right: 3%;
}

.editprofileoption{
	padding: 2px 10px 15px 10px;
	margin: 0;
	position: absolute;
	background-color: #ffffffed;
    z-index: 1020;
    top: 29px;
    right: 0;
    width: 120px;
	border-radius: 5px;
	box-shadow: 2px 2px 7px -3px rgba(0,0,0,0.75);
-webkit-box-shadow: 2px 2px 7px -3px rgba(0,0,0,0.75);
-moz-box-shadow: 2px 2px 7px -3px rgba(0,0,0,0.75);
}


@media (min-width: 992px){
	.me-lg-3 {
    margin-right: 1rem!important;
}
}

.btn:focus,.btn:active {
    outline: none !important;
    box-shadow: none;
 }

 .hometabdiv{
	padding: 0px;
margin: 0px;
	    border-bottom: 1px solid #bfbfbf;
}

.hometabdiv ul {
	padding: 0px;
margin: 0px 0px;
	   
}

.hometabdiv ul li{
	padding: 5px 5px;
    margin: 20px 0px 0px 0px;
	
	font-size: .9rem;
    color: #808080;
	display: inline-block;
}

.hometabdiv ul li a{
	padding: 10px 20px 5px 20px;
	margin: 0px;
	font-size: 1rem;
    color: #808080;
	
}

.hometabdiv ul li a:hover{
	color: #000;
	text-decoration: none;
}
.hometabdiv .activeTab{
	color: white !important;
	border-bottom: 2px solid #003246;
	font-weight: bold;
	background: #003246;
}
.breadcrumbmaindiv{
	width: 100%;
	padding: 0px 0px 5px 0px;
	margin: 0px 0px 10px 0px;
	font-size: 12px;
    color: #3c4e71;
    font-weight: 400;
	    border-bottom: 1px solid #dcdcdc;
}
.breadcrumbmaindiv.page-subtitle{
    font-size: 12px;
    font-weight: lighter;
    
}
.breadcrumbmaindiv a{
	
    color: #3c4e71;
   
}

.breadcrumbmaindiv a:hover{
	
    color: #3c4e71;
   
}



/* 18 MAY */
/* start of uploadfile css */
.demoscandoumentdiv2{
	padding: 0px;
	margin: 0px;
    max-height: 650px;
	overflow: scroll;
    width: 400px;
	background-color: #fff;
    position: relative;
	
}
.demoscandoumentheaderdiv {
    padding: 10px;
    text-align: center;
    background-color: #003246;
    color: #FFFFFF;
}



.pagelabel{
	padding: opx 0px;
	margin: 10px 0px 0px 0px; 
	/* font-family: 'Open Sans', sans-serif; */
    font-family: 'helvetica';
	font-size: 12px; 
	color: #879096;
}

.anotateinputdiv{
	padding: 0px;
	margin: 0px;
	background-color: #fff;
	width: 100%;
	border-bottom: #d4d4d4 1px solid;
}


.anotateinput{
    font-family: 'helvetica';
	font-size: 12px; 
	color: #094067;
	width: 99%;
	border: none;
	flex-wrap: wrap;
}
.anotateinput::-webkit-scrollbar {
    /* width:3px;
    height:2px; */
    display: none;
  }
   
.anotateinput::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(233, 151, 151, 0.3);
  }
   
  .anotateinput::-webkit-scrollbar-thumb {
    background-color: rgb(168, 167, 164);
    outline: 1px solid rgb(248, 229, 193);
  }

.dragdropmaindiv form{
 position: absolute;
  /*top: 50%;
  left: 50%;*/
	left: 0;
	right: 0;
  margin-top: 0px;
  margin-left: 0px;
	margin: auto;
  width: 100%;
  height: 200px;
  border: 4px dashed #6a6b6b;
}
.dragdropmaindiv form p{
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 170px;
  color: #15a084;
  /* font-family: Arial; */
  font-family: helvetica;
  font-size: 12px;
}
.dragdropmaindiv form input{
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  outline: none;
  opacity: 0;
  cursor: pointer;
}
.dragdropmaindiv form button{
  margin: 0;
  color: #fff;
  background: #16a085;
  border: none;
  width: 100%;
  height: 35px;
  margin-top: 0px;
  margin-left:0px;
  border-radius: 4px;
  border-bottom: 4px solid #117A60;
  transition: all .2s ease;
  outline: none;
}
.dragdropmaindiv form button:hover{
  background: #149174;
	color: #0C5645;
}
.dragdropmaindiv form button:active{
  border:0;
}

.dragdropmaindiv{
	padding: 0px;
	margin: 0px;
	height: 220px;
	position: relative;
}

/* nontemplated css starts here */
.dragdropmaindivNonTemplated{
	padding: 0px;
	margin: 0px;
	height: 220px;
	position: relative;
}
.dragdropmaindivNonTemplated form{
    position: absolute;
     /*top: 50%;
     left: 50%;*/
       left: 0;
       right: 0;
     margin-top: 0px;
     margin-left: 0px;
       margin: auto;
     width: 100%;
     height: 200px;
     border: 4px dashed #6a6b6b;
   }
   .dragdropmaindivNonTemplated form p{
     width: 100%;
     height: 100%;
     text-align: center;
     line-height: 170px;
     color: #15a084;
     /* font-family: Arial; */
     font-family: helvetica;
     font-size: 12px;
   }
   .dragdropmaindivNonTemplated form input{
     position: absolute;
     margin: 0;
     padding: 0;
     width: 100%;
     height: 100%;
     outline: none;
     opacity: 0;
     cursor: pointer;
   }
   .dragdropmaindivNonTemplated form button{
     margin: 0;
     color: #fff;
     background: #16a085;
     border: none;
     width: 100%;
     height: 35px;
     margin-top: 0px;
     margin-left:0px;
     border-radius: 4px;
     border-bottom: 4px solid #117A60;
     transition: all .2s ease;
     outline: none;
   }
   .dragdropmaindivNonTemplated form button:hover{
     background: #149174;
       color: #0C5645;
   }
   .dragdropmaindivNonTemplated form button:active{
     border:0;
   }
/*nontemplated css ends here */


.fileslistaindiv{
	padding: 0px;
	margin: 0px;
	position: relative;
}


.documentlist{
	padding: 0px;
	margin: 0px 0px 30px 0px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.documentlist ul{
	padding: 0px;
	margin: 0px;
    height: 50px;
    /* flex: 0 1 5%;
    width: 25%; */
}
.start_reg
{
    padding-top: 20px;
}
.documentlist ul li{
	padding: 10px 10px;
	margin: 0px 5px 0px 0px;
	display: inline-block;
	border-radius: 3px;
	background-color: #fff;
	color: #000;
	font-size: .7rem;
	min-width: 150px;
}

.documentlist ul li span{
	float: right;
	margin: 0px 0px 0px 15px;
}

.selectfile{
	    background-color: #1f6bff !important;
    color: #fff !important;
}


.divider{
	margin: 10px 0px;
	height: 1px;
	background-color: #b9b8b8;
}
/* end of uploadfile css */
.loadingmaindiv{
	padding: 0px;
	margin: 0px auto;
	background-color: #fff;
}

.loadingdiv{
	padding: 0px;
	margin: 0px auto;
	width: 400px;
}

.loadingdiv img{
	padding: 0px;
	margin: 0px auto;
	width: 400px;
}

.documentlistReview{
	padding: 0px;
	margin: 0px 0px 30px 0px;
}

.documentlistReview ul{
	padding: 0px;
	margin: 0px;
}


.documentlistReview ul li{
	padding: 10px 10px;
	margin: 0px 0px 5px 0px;
	display:block;
	border-radius: 3px;
	background-color: #fff;
	color: #000;
	font-size: .7rem;
	min-width: 150px;
}

.documentlistReview ul li span{
	float: right;
	margin: 0px 0px 0px 15px;
}

.topicon{
	height:40px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
}
.topiconIntelligent{
	height:40px;
    float: right;
    justify-content: space-between;
    margin-bottom: 15px;
}
.inthead{
    margin-top:0px;
    height:100%;
}

.topIconsRight{
    display: flex;
    margin-right:30px;
}

.topIconsLeft{
    margin-left:14px;
}
#documentList{
    width:300px;
 
}
#documentList .css-yk16xz-control{
    border-radius: 0px;
}
#documentList .css-yk16xz-control:hover{
    border-radius: 0px;
}
/* toaster css */
.toasterVision {
    position: absolute;
    z-index: 100000000000100 !important;
  }
#generateBtn{
    position: relative;
    height: 39px;
    width: 190px;
    padding-top: 4px;
    background: #003246;
    font-size: 13px;
    font-family: helvetica;
    color: white;
    margin-top: 0px;
}
#removeBtn{
    position: relative;
    height: 39px;
    width: 190px;
    padding-top: 4px;
    background: #460006;
    font-size: 13px;
    font-family: helvetica;
    color: white;
    margin-top: 0px;
}
#apiKeyGenBox{
    position:relative;
    left:50%;
    top:50%;
    height:30%;
    width:60%;
    transform:translate(-50%,-50%);
    border:1px soid transparent;
    -webkit-box-shadow:0 1px 4px rgba(126, 123, 123, 0.3), 0 0 40px rgba(80, 79, 79, 0.1);
    -moz-box-shadow:0 1px 4px rgba(141, 139, 139, 0.3), 0 0 40px rgba(104, 103, 103, 0.1);
         box-shadow:0 1px 4px rgba(121, 120, 120, 0.3), 0 0 40px rgba(92, 92, 92, 0.1);

}
.selectFilter{
    width:200px;
}

.nuocrinputText{
    height: 12px;
    border-radius: 0;
    box-shadow: none;
    align-items: center;
    background-color: hsl(0,0%,100%);
    border-color: hsl(0,0%,80%);
    border-style: solid;
    border-width: 1px;
    cursor: default;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    min-height: 38px;
    outline: 0 !important;
    position: relative;
    -webkit-transition: all 100ms;
    transition: all 100ms;
    box-sizing: border-box;
    width: 150px;
    font-size: 13px;
    height: 0px;
    font-family: 'helvetica';

}
/* Templates searchbar custom css 28/05/2021 begin*/

.menu-search .menu-search-input .form-control{
    height: calc(2.25rem + 4px);
    cursor:text;
    outline: none;
    border-radius: 0px;
    display: block;
    padding-left: 46px;
    border-bottom: 1px solid #a2a5ab;
    border: 1px solid #ced4da;
    box-shadow: none !important;
    /* font-family: 'Source Sans Pro', sans-serif; */
    font-family: 'helvetica';
    font-size: 14px;
    color: #495057;
    background-color: #fff

}
/* Templates searchbar custom css 28/05/2021 end*/


#confidenceTab::-webkit-scrollbar {
    width:1px;
  }
   
#confidenceTab::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
   
#confidenceTab::-webkit-scrollbar-thumb {
    background-color: #003246;
    outline: 1px solid #003246;
  }

  /* Document search css begin */


.page {
	/*padding: 20px 30px;*/
	/* min-height: 100vh; */
	padding: 0px 30px;

}
.space-between {
	justify-content: space-between;
}


.btn-theme {
	background: #003246;
	color: #FFF;
	font-size: 13px;
	font-family: 'helvetica';

}
.bg-transparent {
	background-color: transparent;
}

.btn-view {
	background-color: transparent;
	color: #335B6B;
	border: 1px solid #335B6B;
}
.border-radius-0 {
	border-radius: 0;
}
a, a:active, a:hover {
	text-decoration: none;
}
.f-12 {
	font-size: 12px;
}
.f-13 {
	font-size: 13px;
}
.text-gray {
	
	color: #545454;
}


.add-new-header{
    display: flex;
    justify-content: space-between;
}
.add-new-header h1 {
    font-size: 17px;
    -webkit-text-stroke-width: thin;
    color: #333;
}

.box-inner {
	border: 1px solid #EEEEEE;
	border-radius: 3px;
	min-height: 100px;
	padding: 10px;
	margin: 5px 0;
	position: relative;
}

.box-content {
	margin: 20px 0;
}

.filter-wrap {
	padding-bottom: 8px;
}
.filter-wrap {
	border-bottom: 1px solid #F1F1F1;
}
.filter-wrap label {
	color: #171717;
	font-size: 11px;
	font-family: 'helvetica bold';
	margin-top: 20px;
	margin-bottom: 0;
}
.filter-wrap select {
	border-radius: 0;
	color: #212121;
	font-size: 12px;
	margin: 8px 0;
}
.filter-wrap select option {
	font-size: 12px;
}
.document-title {
	font-size: 14px;
	margin: 5px 0;
	font-family: 'helvetica bold';
	color: #007EA6;
}
.document-wrap li{
	padding: 15px 0;
	border-bottom: 1px solid #F1F1F1;
    list-style-type: none;
}
.document-wrap li p {
	font-size: 12px;
	margin: 0;
}
.document-wrap li p span {
    margin-right: 10px;
    vertical-align: middle;
}

.document-wrap li button {
	padding: 4px;
	font-size: 11px;
	width: 70px;
	text-align: center;
}


.col-grid {

	margin: 8px 0;
}
.modal-footer-btn .btn {
	padding: 8px 35px;
	text-transform: uppercase;
}

/*modal css*/
.dynamic-modal-form .modal-dialog {
    max-width: 95%;
    width: 85%;
    margin: 0px 0px 0px auto;
    overflow-y: initial !important
}
.dynamic-modal-form .modal-content {
    border-radius: 0px;
    height: 100vh;
    padding: 20px;
}
.dynamic-modal-form .modal-header {
    border: none;
}
.dynamic-modal-form .modal-header h5 {
    border: none;
    font-size: 24px;
    padding-top: 13px;
}
.dynamic-modal-form .modal-header .btn-close {
  
    position: relative;
}
.dynamic-modal-form .form-main {
    height: 100%;
}
.dynamic-modal-form .form-right {
    width: 50%;
    float: left;
    padding-left: 20px;
}
.dynamic-modal-form .form-div {
    /* box-shadow: 0px 0px 1px #14121281; */
    border: 1px solid #F2F2F2;
    padding: 15px;
    border-radius: 3px;
    max-height: 80vh;
    /* overflow: auto; */
}
.dynamic-modal-form .form-div .top-details p:nth-child(2) {
    margin-left: 30px;
}
.dynamic-modal-form .form-div p small {
    font-size: 13px!important;
}
.dynamic-modal-form .form-inpit-div {
    width: 50%;
    float: left;
    padding-right: 50px;
    position: relative;
}
.dynamic-modal-form .form-inpit-div {
    width: 50%;
    float: left;
    padding-right: 50px;
    position: relative;
}

.dynamic-modal-form .form-inpit-div.w-25 {
    width: 25%;
}

.dynamic-modal-form .form-inpit-div input {
    font-size: 14px;
    color: gray;
    padding: 0.5rem .75rem;
}

.dynamic-modal-form .form-inpit-div select {
    width: 100%;
    padding: 0.45rem .75rem;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    font-size: 14px;
    color: gray;
   
    background-size: 9px;
    background-position-y: 16px;
}
.dynamic-modal-form .form-inpit-div input:focus {
    box-shadow: none!important;
}
.dynamic-modal-form .form-inpit-div .close-icon {
    color: white;
    font-size: 9px;
    border-radius: 50%;
    background-color: #003246;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 28px;
    cursor: pointer;
}
.dynamic-modal-form .form-footer {
    margin-top: 80px;
}
.dynamic-modal-form .form-footer button {
    background: #003246;
    border: none;
    border-radius: 3px;
    padding: 8px 58px;
    font-size: 12px;
    margin-right: 20px;
    border: 1px solid #003246;
}


.dynamic-modal-form .form-footer button:focus, .dynamic-modal-form select:focus,.dynamic-modal-form textarea:focus {
    box-shadow: none!important;
}
.dynamic-modal-form .main-ul li {
    list-style-type: none;
    padding-right: 10px;
    font-size: 14px;
}
.dynamic-modal-form .form-footer {
    margin-top: 40px;
}
.parent-modal .modal-dialog {
    width: 45%;
}
.parent-modal .form-right {
    width: 100%;
    padding-left: 0px;
}
.comments-box {
    resize: none;
    height: 80px; 
}
.parent-modal .comments-box {
    resize: none;
    height: 100px; 
    width: 540px;
}
.parent-modal .form-footer {
    margin-top: 20px!important;
}
@media only screen and (max-width: 1700px) {
    .dynamic-modal-form .checkbox-check.w-33 {
        width: 45%;
    }
    .form-details-div p {
        width: 32%;
    }
    .form-details-div .wid-50 {
        width: 64%;
    }
    .parent-modal .form-details-div p {
        width: 140px;
    }
}
@media only screen and (max-width: 1450px) {
    .dynamic-modal-form .modal-header h5 {
        padding-top: 0px;
    }
    .dynamic-modal-form .modal-dialog {
        max-width: 95%;
        width: 90%;
    }
    .dynamic-modal-form .form-div .top-details p:nth-child(2) {
        margin-left: 0px;
    }
    .dynamic-modal-form .form-div .top-details p, .form-details-div p {
        font-size: 14px;
    }
    .dynamic-modal-form .form-inpit-div {
        padding-right: 30px;
    }
    .dynamic-modal-form .form-inpit-div .close-icon {
        right: 12px;
    }
    /* .dynamic-modal-form .form-inpit-div input {
        padding: 0.45rem .75rem;
    } */
    .dynamic-modal-form .form-footer {
        margin-top: 50px;
    }
    .dynamic-modal-form .form-footer {
        margin-top: 20px;
    }
    .folder-img {
        left: 24px;
        top: 40px;
    }
    .parent-modal .modal-dialog {
        width: 55%;
       
    }
}

@media only screen and (max-width: 1050px) {
    .dynamic-modal-form .form-footer button {
        margin-bottom: 10px;
        padding: 8px 38px;
    }
    .dynamic-modal-form .form-inpit-div.w-25 {
        width: 50%!important;
    }
}
@media only screen and (max-width: 993px) {
    .dynamic-modal-form .form-inpit-div {
        width: 100%!important;
    }
    .dynamic-modal-form .main-ul li {
        width: 100%!important;
    }
    .parent-modal .modal-dialog {
        width: 70%;
    }
}

  .form-div::-webkit-scrollbar-thumb {
    background: #E7E7E7;
    border-radius:10px;
  }
  
  
.grade-icon {
	max-width: 18px;
	margin-right: 8px;
}
.tab-content-style {
	margin: 0px 10px;
}
.pipe-line {
	position: relative;
}
.pipe-line:after, .pipe-line:before {
	content: '';
	position: absolute;
	top:50%;
	right: -7px;
	transform: translateY(-50%);
	height: 10px;
	width: 1px;
	background-color: #969D9D;
}
.pipe-line:before {
	left: -7px;
	right: auto;
}
.modal {
    padding-right: 0 !important;
}
.modal-tab-section .box-content, .modal-tab-section .tab-content-style  {
    margin: 0;
}
.modal-tab-section .box-content .box-inner{
    margin: 0;
    padding: 0;
    border: 0;
}

.onload-search-wrap {
    position: absolute;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    max-width: 300px;
}
.onload-search-wrap .circle-search-wrap {
    background-color: #F2F2F2;
    text-align: center;
    border-radius: 50%;
    margin: 0 auto;
    padding: 40px 40px;
    display: inline-block;
}
.onload-search-wrap .circle-search-wrap img {
    max-width: 70px;
}
.document-search .box-inner {
    min-height: calc(100vh - 155px);
}
#react-paginate ul {
    display: inline-block;
    margin-left: 300px;
    padding-left: 0;
    margin-top: 90px;
     /* margin: 30px 150px 50px 250px; */
}
    
#react-paginate li {
    display: inline-block;
    border: 1px solid rgb(224, 224, 224);
    color: #000;
    cursor: pointer;
    /* margin-right: 3px; */
    border-radius: 5px;
    margin-bottom: 5px;
}
    
#react-paginate li a {
    padding: 2px 5px;
    display: inline-block;
    color: #000;
    outline: none;
}
    
#react-paginate li.active {
    background: rgb(224, 224, 224);
    outline: none;
}
    
#react-paginate li.pagination__link--disabled {
    background:#ffffff;
    outline: none;
    visibility: hidden;
}
#document-pagination {
    text-align: center;
    margin-top: 20px;
  }
  #document-model-pagination {
    text-align: center;
    padding: 11px;
  }
  .btn-close {
    box-sizing: content-box;
    width: 8px;
    height: 8px;
    padding: .25em .25em;
    color: #000;
    background: url(./images/close.png) center center no-repeat;
    border: 0;
    border-radius: .25rem;
    opacity: .5;
    background-size: contain;
    cursor: pointer;
}
@media only screen and (max-width: 993px) {
    .common-modal .modal-dialog {
        width: 80%;
    }
}
/* custom css filter in document search and activity log */

.box-inner .btn-secondary,.dashbaordgriddiv .btn-secondary{
    background: #003246;
    border: none;
    font-size: 12px;
    margin-right: 20px;
    border: 1px solid #003246;
    height: 38px;
    border-radius: 4px;
    display: inline
}
.box-inner .btn-admin-div{
   padding-top: 4px;
  

}


.react-datepicker-wrapper input{
     border: none;
     width:100%
}  
     .date-container{
        position: relative;
        box-sizing: border-box;
        display: block;
        width: 100%;
      
     }
     .valuecontrol{
         width: 100%;
        align-items: center;
        background-color: hsl(0,0%,100%);
        border-color: rgb(177, 175, 175);
        border-radius: 4px;
        border-style: solid;
        border-width: 1px;
        cursor: default;
        display: flex;
        flex-wrap: wrap;
        -webkit-box-pack: justify;
        justify-content: space-between;
        min-height: 38px;
        outline: 0 !important;
        position: relative;
       font-size: 12px;
     }
     .valuecontainer{
         width:100%;
        -webkit-box-align: center; 
        align-items: center;
        display: flex;
        flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0%;
    flex-wrap: wrap;
    padding: 2px;
    position: relative;
    overflow:visible;
    box-sizing: border-box;
   
}
    
.valuecontainer  >  div.react-datepicker-wrapper >,div.react-datepicker__input-container>input.react-datepicker-ignore-onclik {
   width: 100%;

}
.react-datepicker__input-container{
    border: none;
}
.react-datepicker-ignore-onclik{
    border: none;
}

.react-datepicker-wrapper {
    width: 100%;
  }
  

 
 .modal-content .modal-body .buttons button{
    background: #003246;
    border: none;
    border-radius: 3px;
    padding: 8px 58px;
   
    font-size: 12px;
    margin-right: 20px;
    border: 1px solid #003246;
}
.modal-content .modal-body .buttons .cancel-button{
color: #003246;
background: transparent;
transition: all .4s ease;
}
.modal-content .modal-body .buttons{
  
    margin: 15px;
   
}

.document-title-modal{
font-size: 17px;
    margin: 5px 0;
    font-family: 'helvetica bold';
    color: #007EA6;
}
 .react-datepicker__close-icon::before,
 .react-datepicker__close-icon::after {
   background-color:#003246;
 
  
 }

  
/* custom css end */
/* Document search css end */

/* Dashboard  */
.dashmetricsdiv{
	background-color: #f2f0fa;
	padding: 15px 10px; 
	margin: 10px auto; 
	border-radius: 10px;
}

.metricsicon{
	width: 50px;
	height: 50px;
	border-radius: 50px;
	background-color: #3fc9ff;
	border: 5px solid #ffffff91;
	color: #fff;
	padding: 7px;
	text-align: center;
	font-size: 18px;
}

.dashmetricvalue {
	padding: 0px;
	margin: 0px;
	
	  
}
.dashmetricvalue p{
	padding:0px 0px; 
	margin: auto; 
	/* font-family: 'Open Sans', sans-serif; */
    font-family: 'helvetica';
	font-size: 14px; 
	color: #2B2B2B;
	text-align: left;
	font-weight: 600;
	
	  
}

.dashmetricvalue h2 {
	padding:0px 0px; 
	margin: auto; 
	/* font-family: 'Open Sans', sans-serif; */
    font-family: 'helvetica';
	font-size: 20px; 
	color: #2B2B2B;
	text-align: left;
	font-weight: 600;
	
	  
}

  .dynamic-form-div {
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 20px 70px;
    margin-top: 20px;
  }
  .filter-div form {
    width: 100%;
  }
  .filter-div form .form-group {
    width: 20%;
  }
  .filter-div form .form-group select {
    width: 90%;
  }
  .filter-div button {
    color: #fff;
    border: 0px;
    background: #003246;
    padding: 4px 20px;
    border-radius: 1px;
    font-size: 13.5px;
    white-space: nowrap;
    font-weight: 500;
    /* text-transform: uppercase; */
    -webkit-text-stroke-width: thin;
  }
  .filter-div p {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 5px;
    -webkit-text-stroke-width: thin;
  }
  .filter-div button span {
    font-size: 18px;
  }
  .dynamic-form-div ul li div {
    position: relative;
    padding: 5px 0px;
  }
  .calendar {
      padding-bottom: 20px;
    /* border-bottom: 1px solid #f1f1f1; */
}
  .dynamic-form-div ul li {
    list-style-type: none;
  }
  .dynamic-form-div ul li p {
    color: #171717;
    font-size: 14px;
    padding-left: 19px;
    margin-bottom: 6px;
  }
  .dynamic-form-div ul li p:first-child {
    color: #007ea6;
    font-weight: 600;
    font-size: 18px;
    padding-left: 0px;
    -webkit-text-stroke-width: thin;
    margin-bottom: 8px;
  }
  .dynamic-form-div ul li p:first-child span.active,
  .dynamic-form-div ul li p:first-child span.inactive {
    color: #44a882;
    background: #def8ee;
    text-align: center;
    padding: 5px 14px;
    font-weight: 500;
    font-size: 13.5px;
    border-radius: 20px;
    margin-left: 10px;
    -webkit-text-stroke-width: initial;
  }
  .dynamic-form-div ul li p:first-child span.inactive {
    background: #f1f1f1;
    color: #666;
  }
  .dynamic-form-div ul li p {
    color: #ddd;
  }
  .dynamic-form-div ul li p span {
    position: relative;
    color: #666;
    margin-left: 0px !important;
    font-size: 14px;
    -webkit-text-stroke-width: thin;
  }
  .dynamic-form-div ul li p span.nurse::before {
    content: "";
    position: absolute;
    left: -25px;
    top: 0;
    background-size: contain;
    width: 17px;
    height: 17px;
  }
  .dynamic-form-div ul li p span.calendar::before {
    content: "";
    position: absolute;
    left: 3px;
    top: 0;
  
    background-size: contain;
    width: 17px;
    height: 17px;
  }
  .dynamic-form-div ul li p span.grade::before {
    content: "";
    position: absolute;
    left: -25px;
    top: 0;
  
    background-size: contain;
    width: 17px;
    height: 17px;
  }
  .dynamic-form-div ul li p:nth-child(3) {
    padding: 0px;
    margin: 0px;
    margin-top: 14px;
  }
  .dynamic-form-div ul li p:nth-child(3) span {
    padding: 5px 10px;
    border-radius: 20px;
    margin-right: 5px;
    background: #f1f1f1;
    font-size: 13px;
    -webkit-text-stroke-width: thin;
  }
  .dynamic-list {
    padding: 0px;
  }

.drop-more-main {
  float: right;
  top: -80px;
  border-bottom: none !important;
}

.drop-more-main img {
    height: 14px;
    margin-bottom: 16px;
}

.more-btn {
    box-shadow: none;
    background: transparent;
    border: 0;
}

.more-btn.dropdown-toggle::after {
    display: none;
}

.drop-more {
    position: absolute !important;
    transform: translate3d( 60px, 182px, 0px) !important;
    top: 0px !important;
    left: 0px !important;
    will-change: transform !important;
}

#activityLogTable{
    font-size: 0.5 px;
    line-height: 2;


}
#thead_actvtylog{
    background-color:rgb(224, 223, 223) ;
    line-height: 191%;
    font-weight: 800 px;
}

  #ActLog_td,#ActLog_th{
    padding: 10px;
 /* border: 1px solid rgb(99, 25, 25); */
    border-collapse: collapse;
  }
  .nav-item.active:after {
    
    content: '';
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    height: 6px;
    width: 6px;
    border-radius: 50%;
    background-color: #003246;
    cursor: pointer;
}
/* nav-item{
   
        color: #2E2E2E;
        font-size: 12px;
        padding: 8px 15px;
        font-family: "helvetica bold";
   
} */
.dataRecognition{
    color: #2E2E2E;
    font-size: 12px;
    padding: 8px 15px;
    font-family: "helvetica bold";
    cursor: pointer;
}
 .nav-item.active .nav-link {
    color: #003246;
    cursor: pointer;
   
}
.nav-item .nav-link {
    color: #2E2E2E;
    font-family: 'helvetica bold';
    font-size: 12px;
    padding: 8px 15px;
    cursor: pointer;
}
.nav-item{
    position :relative;
    cursor: pointer;
}
#row-date {
    width: 16%;
  }
  #row-doc{
      width:20%
  }
  #row-pages{
      width:13%
  }
  #row-indstry{
    width:15%
}
#row-type{
    width:15%
}
.dataRecSearchBtn{
    border:1px solid transparent;
    background: #003246;
    color:#fff;
    font-size: 12px;
    font-weight: lighter;
    width:100px;
    height:31px;
}
#Name{
    visibility:hidden;
}

/*================================ Scan Result View page Start ==================================*/
.custom1{
    padding-left: 30px;
}
.scandoumentdiv{
	background-color: #fff;
    object-fit:contain;
    border: 1px solid rgba(0, 0, 0, 0.3);
    width: 100%;
	padding: 0px;
	margin: 0px;
	min-height: 500px;
	max-height: 700px;
    cursor:grab;
	overflow: auto;
}
.custom2{
    padding-right: 30px;
}
.scandoumentdiv2{
	padding: 15px;
	margin: 0px;
    max-height: 698px;
	overflow-y:scroll;
    overflow-wrap: break-word;
    width: 100%;
	background-color: #fff;
    position: relative;
	border: 1px solid rgba(0, 0, 0, 0.3);
    z-index: 3;
}

.scandoumentdiv::-webkit-scrollbar {
    width:1px;
    height:0px;
  }
   
.scandoumentdiv::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
   
.scandoumentdiv::-webkit-scrollbar-thumb {
    background-color: #003246;
    outline: 1px solid #003246;
  }
.scandoumentdiv2::-webkit-scrollbar {
    width:1px;
    height: 0px;
  }
   
.scandoumentdiv2::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
   
.scandoumentdiv2::-webkit-scrollbar-thumb {
    background-color: #003246;
    outline: 1px solid #003246;
  }
.custom1 {
	width: 65%;
    margin-right: 5%;
}

.custom2 {
	width: 30%;
}
#reviewSaveBtn{
    border:1px solid transparent;
    background: #034439;
    color:#fff;
    font-size: 12px;
    font-weight: lighter;
    width:12rem;
    height:3rem;
    border-radius: 0px;
    margin-right: 5px;
}
#reviewDownloadBtn{
    border:1px solid transparent;
    background:#460006;
    color:#fff;
    font-size: 12px;
    font-weight: lighter;
    width:12rem;
    height:3rem;
    border-radius: 0px;
}
#jsonBtn{
    margin-left: 5px;
    float:right;
    border:1px solid #6b020b;
    color:#fff;
    font-size: 10px;
    background:#69020b;
    border-radius:10px;
    cursor:pointer;
    padding: 2px;
    width:40px;
}
#jsonBtn:hover{
    background: #fff;
    color: #69020b;
}
.jsonModel{
    width:90%;
   z-index:10;
   border:1px solid #ddd;
   padding:8px;
   font-size: 12px;
   /* margin-top:15px; */
   position:absolute;
   background: #fff;
   visibility: hidden;
   color:black;
   font-size: 12px;
   font-weight:lighter;
   /* min-height: 300px; */
   box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}
.jsonModelActive{
    z-index:10;
    border:1px solid #ddd;
    padding:8px;
    font-size: 12px;
    margin-top:5px;
    position:absolute;
    background: #fff;
    visibility: visible;
    color:black;
    font-size: 12px;
    font-weight:lighter;
    /* min-height: 300px; */
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}
.jsonContentArea::-webkit-scrollbar {
    width:3px;
    height:2px;
    /* display: none; */
  }
   
.jsonContentArea::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(233, 151, 151, 0.3);
  }
   
  .jsonContentArea::-webkit-scrollbar-thumb {
    background-color: rgb(168, 167, 164);
    outline: 1px solid rgb(248, 229, 193);
  }
.jsonContentArea{
    background: rgb(248, 229, 193);
    width: 100%;
    height:200px;
    overflow:scroll;
    cursor: grab;
    display: flex;
    flex-wrap: wrap;
}
.jsonContentArea pre::-webkit-scrollbar{
    display: none;
}

#jsonCopyBtn{
    border:1px solid transparent;
    font-size: 12px;
    float:left;
    cursor:pointer;
    margin:3px;
    width:50px;
    height:20px;
}
#jsonCopyBtn:hover{
    background-color:rgb(177, 176, 176);
    color: white;
}
#modelCloseBtn{
    border:1px solid transparent;
    float:right;
    cursor:pointer;
    width:18px;
    height:18px;
    border-radius: 50%;
}
#modelCloseBtn:hover{
    background-color:rgb(177, 176, 176);
    color: white;
}
.docsDropdownScanResult{
    height: 12px;
    border-radius: 0;
    box-shadow: none;
    align-items: center;
    background-color: rgb(255, 255, 255);
    border-color: hsl(0,0%,80%);
    border-style: solid;
    border-width: 1px;
    cursor: default;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    min-height: 38px;
    outline: 0 !important;
    position: relative;
    -webkit-transition: all 100ms;
    transition: all 100ms;
    box-sizing: border-box;
    min-width: 150px;
    font-size: 13px;
    height: 0px;
    font-family: 'helvetica';
    padding: 5px;
}
/*================================ Scan Result View page End ==================================*/


/* active connections css begin */
.dynamicForm {
    padding: 20px;
  }
  .dynamicForm .dynamic-form-header-connections {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ddd;
  }
  .dynamicForm .dynamic-form-header-connections h1 {
    font-size: 19px;
    /* font-weight: 600; */
    -webkit-text-stroke-width: thin;
    color: #333;
  }
  .dynamic-form-div-activeconnections {
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 20px 30px;
    margin-top: 20px;
  }
  .dynamic-form-div-activeconnections .header {
    position: relative;
    width:100%
  }
  .dynamic-form-div-activeconnections .header p {
    font-size: 14px;
    color: #666;
    font-weight: 500;
    position: relative;
  }
  .form-filter {
    display: flex;
    justify-content: space-between;
  }
  
  .filter-div-activeconnections form {
    width: 100%;
  }
  .filter-div-activeconnections form .form-group {
    width: 20%;
  }
  .filter-div-activeconnections form .form-group select {
    width: 90%;
  }
  .header button {
    color: #fff;
    border: 0px;
    background: #003246;
    padding: 4px 20px;
    border-radius: 1px;
    font-size: 13px;
    white-space: nowrap;
    margin-right: 0px;
    /* font-weight: 500; */
    /* text-transform: uppercase; */
    /* -webkit-text-stroke-width: thin; */
  }
  .filter-div-activeconnections p {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 5px;
    -webkit-text-stroke-width: thin;
  }
  .dynamic-form-div-activeconnections .header button span {
    font-size: 18px;
  }
  .dynamic-form-div-activeconnections .header .hometabdiv ul  {
   width:100%
  }
  .dynamic-form-div-activeconnections .header .hometabdiv ul li:last-child  {
   
    float: right;
   }

  .dynamic-form-div-activeconnections ul li div {
    position: relative;
    padding: 19px 0px;
    border-bottom: 1px solid #f1f1f1;
  }
  .dynamic-form-div-activeconnections ul li {
    list-style-type: none;
  }
  .dynamic-form-div-activeconnections ul li p {
    color: #171717;
    font-size: 14px;
    padding-left: 19px;
    margin-bottom: 6px;
  }
  .dynamic-form-div-activeconnections ul li p:first-child {
    color: #007ea6;
    /* font-weight: 600; */
    font-size: 18px;
    padding-left: 0px;
    -webkit-text-stroke-width: thin;
    margin-bottom: 8px;
  }
  .dynamic-form-div-activeconnections ul li p:first-child span.active,
  .dynamic-form-div-activeconnections ul li p:first-child span.inactive {
    color: #44a882;
    background: #def8ee;
    text-align: center;
    padding: 5px 14px;
    font-weight: 500;
    font-size: 13.5px;
    border-radius: 20px;
    margin-left: 10px;
    -webkit-text-stroke-width: initial;
  }
  .dynamic-form-div-activeconnections ul li p:first-child span.inactive {
    background: #f1f1f1;
    color: #666;
  }
  .dynamic-form-div-activeconnections ul li p {
    color: #ddd;
  }
  .dynamic-form-div-activeconnections ul li p span {
    position: relative;
    color: #666;
    font-size: 14px;
    -webkit-text-stroke-width: thin;
  }
  .dynamic-form-div-activeconnections ul li p span.calendar {
    padding-left: 25px;
  }
  .dynamic-form-div-activeconnections ul li p span.nurse::before {
    content: "";
    position: absolute;
    /* background: url(../images/noun_Nurse.png) center center no-repeat; */
    left: -25px;
    top: 0;
    background-size: contain;
    width: 17px;
    height: 17px;
  }
  .dynamic-form-div-activeconnections ul li p span.calendar::before {
    content: "";
    position: absolute;
    background: url(./images/noun_schedule.png) center center no-repeat;
    left: 3px;
    top: 0;
  
    background-size: contain;
    width: 17px;
    height: 17px;
  }
  .dynamic-form-div-activeconnections ul li p span.grade::before {
    content: "";
    position: absolute;
    left: -25px;
    top: 0;
  
    background-size: contain;
    width: 17px;
    height: 17px;
  }
  .dynamic-form-div-activeconnections ul li p:nth-child(3) {
    padding: 0px;
    margin: 0px;
    margin-top: 14px;
  }
  .dynamic-form-div-activeconnections ul li p:nth-child(3) span {
    padding: 5px 10px;
    border-radius: 20px;
    margin-right: 5px;
    background: #f1f1f1;
    font-size: 13px;
    -webkit-text-stroke-width: thin;
  }
  .dynamic-list {
    padding: 0px;
  }
  .f-12 {
    font-size: 14px;
  }
  .f-none {
    float: none !important;
  }
  .dropdown-menu-more {
    align-items: right;
    /* background: url(../images/more.png) center center no-repeat; */
  }
  .preview-input {
    width: 45%;
    margin: 5px 15px;
  }
  .custom-class-img {
    width: 25px !important;
    cursor: pointer;
  }
  .custom-left {
    cursor: pointer;
  }
  .required-asterix {
    color: red;
    size: 10px;
  }
  .drop-more {
    position: absolute;
    transform: translate3d(-133px, 36px, 0px);
    top: 0px;
    left: 0px;
    will-change: transform;
  }
  .drop-more-main-activeconnection {
    float: right;
    top: -80px;
    border-bottom: none !important;
  }
  .form-group .label-size {
    font-size: 12px;
  }
  .form-row .textAreaColor {
    color: #ff6767;
  }
  .div-cus {
    display: contents;
  }
  .select-cus {
    width: 21%;
    margin-left: 10px;
    margin-right: 10px;
  }
  .chk-cus {
    width: 33% !important;
  }
  
  .option-required {
    color: red;
  }
  
  .body-overflow {
    overflow: auto;
  }
  
  .margin-custom {
    margin: 0px !important;
  }
  
  .cursor-custom {
    cursor: pointer;
  }
  
  .file-custom {
    width: 52% !important;
  }
  
  .dynamicFormMaster [type="radio"]:not(:checked) + label:before {
    border: 1px solid grey;
  }
  
  .dynamicFormMaster [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  
  .dynamicFormMaster [type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  
  .dynamicFormMaster [type="radio"]:checked + label:after,
  .dynamicFormMaster [type="radio"]:not(:checked) + label:after {
    content: "";
    width: 8px;
    height: 8px;
    background: #0982a9;
    border: 1px solid #0982a9;
    position: absolute;
    top: 6px;
    left: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    visibility: hidden;
  }
  
  .dynamicFormMaster [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
    content: "";
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
    border: 1px solid #003246;
    border-radius: 100%;
    background: #fff;
    visibility: hidden;
  }
  
  .txt-area-custom {
    height: fit-content !important;
  }
  .label-custom {
    white-space: pre-wrap !important;
    font-size: 12px;
  }
  .configbutton{
color:#000;
font-weight: 600;
background-color: #fff;
transition: all 0.4s ease;
outline: none;
 border-radius: 50%; 
/* border: none; */
/* border-color: #003246; */
background: transparent;
margin-top: 28px;
border: 1px solid #003246;
  }
  .configbutton:hover{
    font-weight: 600;
   
color: #fff;
 background-color:#264d73; 
/* border: none; */
border-radius: 50%;
 /* border-color: #264d73;  */
 border-color: #fff;

margin-top: 28px;
  }
  .configbutton:focus, .button:focus, [type="button"]:focus {
    outline: none;
 }

/* toggle in activeconnection  Email and FTP */

.filter-div-activeconnections   .switch-field {
    display: flex;
    overflow: hidden;
   
  }
  
 .filter-div-activeconnections  .switch-field input {
    position: absolute !important;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    width: 1px;
    border: 0;
    overflow: hidden;
   
  }
  
 .filter-div-activeconnections .switch-field label {
    background-color: white;
    color: lightgrey;
    font-size: 12px;
    line-height: 1;
    text-align: center;
    padding: 8px 16px;
    margin-right: -1px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
    transition: all 0.1s ease-in-out;
   
  }
  
  .filter-div-activeconnections  .switch-field label:hover {
    cursor: pointer;
  }
  
  .filter-div-activeconnections  .switch-field input:checked + label {
    background-color: lightgray;
    box-shadow: none;
    color: black;
  }
  
 .filter-div-activeconnections .switch-field label:first-of-type {
    border-radius: 4px 0 0 4px;
  }
  
  .filter-div-activeconnections .switch-field label:last-of-type {
    border-radius: 0 4px 4px 0;
  }
#configcontainer{
    overflow-y: auto;
    height: 70vh;
    padding: 20px;
    border: 1px solid #d6d2da;
    margin: 15px;
    border-radius: 5px;
}
#configcontainer label {
    font-size: 13px;
    font-family: 'Helvetica'; 
}


  /*-------------actiive connection css ends ------*/


  /*-------------Landing page css starts here------*/
  .wrapper-container{
	width: 100%;
	height:600px;
	padding: 0px;
	margin: 0px;
	background-image: url("images/slide_5.jpg");
	background-position: center;
	background-size: cover;
}
.topheadertext{
	padding: 10px;
	margin: 0px;
}
.topheadertext h2 {
	padding:8px 0px;margin: 20% 0%; 
	
	font-family: 'Open Sans', sans-serif; 
	font-size: 35px; 
	color: #fff;
	text-align: center;
	font-weight: 600;
	line-height: 45px;
	    background-color: #556978;
	
}
.main-content{
	width: 100%;
	padding: 0px;
	margin: 0px;
}
.LandingSspace
{
	padding:20px;
}
.topheader1{
	padding: 10px;
	margin: 0px;
}
.topheader1 img{
	width: 40px;
	padding: 0px;
	margin: 0px 0px;
	display: block;
}
.topheader1 ul{
	padding: 0px;
	margin: 0px;
}
.topheader1 ul li{
	padding:5px 0px; 
	margin: auto; 
	font-family: 'Open Sans', sans-serif;
	font-size: 14px; 
	color: #2B2B2B;
	text-align: left;
	font-weight: normal;
}
.topheader1 h2 {
	padding:18px 0px; 
	margin: auto; 
	font-family: 'Open Sans', sans-serif;
	font-size: 26px; 
	color: #2B2B2B;
	text-align: center;
	font-weight: 600;
	line-height: 45px;
	  
}
.topheader1 p {
	padding:5px 0px; 
	margin: auto; 
	font-family: 'Open Sans', sans-serif;
	font-size: 14px; 
	color: #2B2B2B;
	text-align: center;
	font-weight: normal;  
}
.topheader1 span{
	color: #3da9fc;
	font-weight: 700;  
}
.bottomcomingsoondiv{
	width: 100%;
	background-color: #ececec;
}
.bottomcontent{
	padding: 10px;
	margin: 0px 0px;
}
.topheader{
	padding: 10px;
	margin: 0px;
}
.topheader h2 {
	padding:18px 0px; 
	margin: auto; 
	font-family: 'Open Sans', sans-serif;
	font-size: 26px; 
	color: #2B2B2B;
	text-align: center;
	font-weight: 600;
	line-height: 45px;
	  
}
.topheader span{
	color: #3da9fc;
	font-weight: 700; 
}
.groupnewheading{
	position: absolute;
    top: 0;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.6);
    padding:10px 0px 8px 15px; 
	margin: auto; 
	width: 100%;
}
.groupnewheading h3{
	font-family: 'Open Sans', sans-serif;
	font-size: 14px; 
	color: #fff;
	text-align: left;
	font-weight: 600;
	padding: 5px 0px;
	margin: 0;
}

.groupnewheading h3 a{
	color: #fff;
	
}

.groupnewheading h3 a:hover{
	color: #fff;
	text-decoration: none;
}
.grouptextdiv{
	padding: 0px;
	margin: 0px;
}
.grouptextdiv h3 {
	padding:8px 0px; 
	margin: auto; 
	font-family: 'Open Sans', sans-serif;
	font-size: 18px; 
	color: #fff;
	text-align: left;
	font-weight: 600;
}

.grouptextdiv h3 a {

	color: #fff;
	
}

.grouptextdiv p {
	padding:8px 0px; 
	margin: auto; 
	font-family: 'Open Sans', sans-serif;
	font-size: 12px; 
	color: #fff;
	text-align: left;
	
}
.groupimgdiv{
	padding: 0px;
	margin: 0px;
	width: 100%;
	
}

.groupimgdiv img{
	padding: 0px;
	margin: 0px;
	width: 100%;
	height: 200px;
	object-fit: cover;
}
.groupmaindiv{
	padding: 0px;
	margin: 0px 0px 50px 0px;
	border-radius: 10px;
-webkit-box-shadow: -2px 2px 13px -8px rgba(0,0,0,0.75);
-moz-box-shadow: -2px 2px 13px -8px rgba(0,0,0,0.75);
box-shadow: -2px 2px 13px -8px rgba(0,0,0,0.75);
}
.hvrbox,
.hvrbox * {
	box-sizing: border-box;
}
.hvrbox {
	position: relative;
	display: inline-block;
	overflow: hidden;
	max-width: 100%;
	height: auto;
}
.hvrbox img {
	max-width: 100%;
}
.hvrbox_background {
	width: 400px;
	height: 250px;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
}
.hvrbox .hvrbox-layer_bottom {
	display: block;
}
.hvrbox .hvrbox-layer_top {
	opacity: 0;
	position: absolute;
	top: 44px;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.6);
	color: #fff;
	padding: 15px;
	-moz-transition: all 0.4s ease-in-out 0s;
	-webkit-transition: all 0.4s ease-in-out 0s;
	-ms-transition: all 0.4s ease-in-out 0s;
	transition: all 0.4s ease-in-out 0s;
}
.hvrbox:hover .hvrbox-layer_top,
.hvrbox.active .hvrbox-layer_top {
	opacity: 1;
}
.hvrbox .hvrbox-text {
	text-align: center;
	font-size: 18px;
	display: inline-block;
	position: relative;
	top: 33%;
	left: 50%;
	-moz-transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}
.hvrbox .hvrbox-text_mobile {
	font-size: 15px;
	border-top: 1px solid rgb(179, 179, 179); /* for old browsers */
	border-top: 1px solid rgba(179, 179, 179, 0.7);
	margin-top: 5px;
	padding-top: 2px;
	display: none;
}
.hvrbox.active .hvrbox-text_mobile {
	display: block;
}
.hvrbox .hvrbox-layer_image {
	padding: 0;
	background: none;
}
.hvrbox .hvrbox-layer_slideup {
	-moz-transform: translateY(100%);
	-webkit-transform: translateY(100%);
	-ms-transform: translateY(100%);
	transform: translateY(100%);
}
.hvrbox:hover .hvrbox-layer_slideup,
.hvrbox.active .hvrbox-layer_slideup {
	-moz-transform: translateY(0);
	-webkit-transform: translateY(0);
	-ms-transform: translateY(0);
	transform: translateY(0);
}
.hvrbox .hvrbox-layer_slidedown {
	-moz-transform: translateY(-100%);
	-webkit-transform: translateY(-100%);
	-ms-transform: translateY(-100%);
	transform: translateY(-100%);
}
.hvrbox:hover .hvrbox-layer_slidedown,
.hvrbox.active .hvrbox-layer_slidedown {
	-moz-transform: translateY(0);
	-webkit-transform: translateY(0);
	-ms-transform: translateY(0);
	transform: translateY(0);
}
.hvrbox .hvrbox-layer_slideleft {
	-moz-transform: translateX(100%);
	-webkit-transform: translateX(100%);
	-ms-transform: translateX(100%);
	transform: translateX(100%);
}
.hvrbox:hover .hvrbox-layer_slideleft,
.hvrbox.active .hvrbox-layer_slideleft {
	-moz-transform: translateX(0);
	-webkit-transform: translateX(0);
	-ms-transform: translateX(0);
	transform: translateX(0);
}
.hvrbox .hvrbox-layer_slideright {
	-moz-transform: translateX(-100%);
	-webkit-transform: translateX(-100%);
	-ms-transform: translateX(-100%);
	transform: translateX(-100%);
}
.hvrbox:hover .hvrbox-layer_slideright,
.hvrbox.active .hvrbox-layer_slideright {
	-moz-transform: translateX(0);
	-webkit-transform: translateX(0);
	-ms-transform: translateX(0);
	transform: translateX(0);
}
.hvrbox .hvrbox-layer_scale {
	border-radius: 50%;
	-moz-transform: scale(0);
	-webkit-transform: scale(0);
	-ms-transform: scale(0);
	transform: scale(0);
}
.hvrbox:hover .hvrbox-layer_scale,
.hvrbox.active .hvrbox-layer_scale {
	border-radius: 0%;
	-moz-transform: scale(1);
	-webkit-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
}
.hvrbox .hvrbox-layer_rotate {
	border-radius: 50%;
	-moz-transform: rotateZ(0);
	-webkit-transform: rotateZ(0);
	-ms-transform: rotateZ(0);
	transform: rotateZ(0);
}
.hvrbox:hover .hvrbox-layer_rotate,
.hvrbox.active .hvrbox-layer_rotate {
	border-radius: 0%;
	-moz-transform: rotateZ(360deg);
	-webkit-transform: rotateZ(360deg);
	-ms-transform: rotateZ(360deg);
	transform: rotateZ(360deg);
}
.hvrbox .hvrbox-layer_scale-rotate {
	border-radius: 50%;
	-moz-transform: scale(0) rotateZ(0);
	-webkit-transform: scale(0) rotateZ(0);
	-ms-transform: scale(0) rotateZ(0);
	transform: scale(0) rotateZ(0);
}
.hvrbox:hover .hvrbox-layer_scale-rotate,
.hvrbox.active .hvrbox-layer_scale-rotate {
	border-radius: 0%;
	-moz-transform: scale(1) rotateZ(360deg);
	-webkit-transform: scale(1) rotateZ(360deg);
	-ms-transform: scale(1) rotateZ(360deg);
	transform: scale(1) rotateZ(360deg);
}

/*-------------Landing page css ends here------*/

/*-------------Landing page navbar starts here------*/
.headermenu{
	padding:0px 0px;
	margin: 40px 0px 0px 0px; 
	overflow: auto;
	float: right;/* April 30*/
}

.headermenu ul{
	padding:0px 0px;
	margin: 0px 0px; 
}

.headermenu ul li{
	padding:10px 10px 10px 0px;
	margin: 0px 0px; 
	font-family: 'Open Sans', sans-serif;
	font-size: 14px; 
	font-weight: 600;
	color: #000000;
	text-align: right;
	display: inline-block;
	float: right;
}

.headermenu ul li img{ /* april 30 */
	width: 20px;
}
.headermenu ul li a{
	color: #094067;
	padding:10px 10px 10px 10px; /* April 30*/
	margin: 0px;
	
}
.headermenu ul li a:hover{
	/*background: #3da9fc4f;*/
	color: #094067;
	text-decoration: none;
}
.headermenuactive{
    position: relative;
    height: 39px;
    width: 190px;
    padding-top: 4px;
    background: #EAEAF1;
    font-size: 13px;
    font-family: Helvetica;
    
    margin-top: 0px;
}


.search_categories{
    font-size: 13px;
    padding: 10px 8px 10px 14px;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 6px;
    overflow: hidden;
    position: relative;
  }

.search_categories .select{
width: 100%;
background:url('') no-repeat;
background-position:80% center;
}

.search_categories .select select{
background: transparent;
line-height: 1;
border: 0;
padding: 0;
border-radius: 0;
width: 99%;
position: relative;
z-index: 10;
font-size: 1em;
}