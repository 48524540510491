/* .containermaindiv{
    width: 400px;
    height: 430px;

    background-color: #ededed;
    padding: 0px;
    margin: 0px auto;
    
    -webkit-box-shadow: 10px 13px 21px -12px rgba(0,0,0,0.63);
    -moz-box-shadow: 10px 13px 21px -12px rgba(0,0,0,0.63);
    box-shadow: 10px 13px 21px -12px rgba(0,0,0,0.63);
}

.loginrightdiv{
    width: 400px;
    height: 430px;
    margin:50px auto; 
    float: right;
    padding:0px;
    background-color: white;
    
}

.logosmdiv{
    padding: 0;
    display: none;
}

.logosmdiv img{
    padding: 0;
    width: 100px;
    margin: auto;
}

.logosmdiv{
    padding: 0;
    display: flex;
}

.loginsubdiv{
    width: 275px;
    margin: auto;
    padding: 0;
    overflow: auto;
} */
/* 20 march 2018 */
/* .loginheader{
    font-family: 'Lato', sans-serif;
    font-size: 1.3em;
    font-weight: normal;
    color: #f6821f;
    padding: 5px 0px 0px 0px  ;
    margin:10px auto;
    text-align: left;
    text-transform: uppercase;
}

.username{
    font-family: 'Lato', sans-serif;
    font-size: 0.8em;
    font-weight: normal;
    color: #555555;
    padding: 15px 0px 0px 0px ;
    margin: auto;
    text-align: left;
    text-transform: capitalize;
}
.usernametextdiv{
    padding: 2px;
    margin: 5px 0px 0px 0px;
    background-color: #f5f6fd;
    border: 1px solid #d1d1d1;
    border-radius: 25px;
}

.usernametextdiv span{
    font-family: 'Lato', sans-serif;
    font-size: 0.9em;
    font-weight: normal;
    color: #555555;
    padding: 15px 0px 0px 0px ;
    margin: auto;
    text-align: left;
}

.usernametextfield{
    font-family: 'Lato', sans-serif;
    font-size: 0.8em;
    font-weight: normal;
    color: #2c2c2c;
    padding: 5px 0px 3px 10px ;
    margin: 0;
    width: 85%;
    background-color: transparent;
    border: none;
    
}

.loginbtn{
    width: 100%;
    padding: 6px 30px;
    margin: 20px auto;
    background-color: #e18612;
    font-family: 'Lato', sans-serif;
    font-size: 1em;
    color: #fff;text-align: center;
    border-radius: 25px;
    border: none; */
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ff8c3d+0,ff5969+100 */
    /* background: #ff8c3d; */
     /* Old browsers */
    /* background: -moz-linear-gradient(left,  #ff8c3d 0%, #ff5969 100%);  */
    /* FF3.6-15 */
    /* background: -webkit-linear-gradient(left,  #ff8c3d 0%,#ff5969 100%); */
     /* Chrome10-25,Safari5.1-6 */
    /* background: linear-gradient(to right,  #ff8c3d 0%,#ff5969 100%);  */
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    /* filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff8c3d', endColorstr='#ff5969',GradientType=1 );  */
    /* IE6-9 */
/* }

.loginerror{
    font-family: 'Lato', sans-serif;
    font-size: 0.7em;
    font-weight: bold;
    color: #d00000;
    padding: 0px 0px;
    margin: 0px;
    text-align: center;
}

.socialicons{
    padding: 5px 0px 0px 0px;
    margin: 0;
}

.socialicons ul{
    padding: 0;
    margin: 0;
}

.socialicons ul li{
    padding: 0;
    margin: 0;
    display: inline;
}

.socialicons ul li img{
    padding: 0;
    margin: 0px 5px;
    width: 20px;
} */


/* login eduhealth model css */
body {
    padding: 0px 4% 0px 6%;
    /* background-image: url('../../images/login_bg.png'); */
}
.logo-img {
    position: absolute;
    width: 9%;
    top: 2%;
    left: 30px;
}
.close-a {
    text-align: right;
}
.close-a img {
    width: 15px;
}
.form-div {
    background-repeat: no-repeat;
    background-size: 54%;
    background-position: left;
    background-position-y: 42%;
    height: calc(100vh - 90px);
    position: relative;
    margin: 30px;
    margin-top: 60px;
}
.form-check-input.is-valid~.form-check-label, .was-validated .form-check-input:valid~.form-check-label {
    color: initial;
}
.pass-img {
    position: absolute;
    width: 14px;
    right: 12px;
    top:  30px;
    cursor: pointer;
}
#main-form {
    background: #BFE6EA;
    width: 38%;
    padding: 20px 30px 30px 30px;
    border-radius: 15px;
    position: absolute;
    right: 0;
    top: 51%;
    transform: translateY(-50%);
}
.signin-form {
    padding: 10% 30px 25px 30px!important;
}
.form-group .form-control:focus {
    box-shadow: none!important;
}
#main-form h2 {
    font-weight: 450;
    margin-bottom: 20px;
    font-size: 30px;
    color: #003246!important;
}
.forgot-txt {
    color: #02161f;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
}
.error-msg {
    color: red;
    position: absolute;
    padding: 3px;
}
label {
    font-size: 12px;
    font-weight: 500;
    margin-bottom: .3rem;
}
.form-group {
    margin-bottom: 0.8rem;
}
#radio-div {
    margin-bottom: 1.5rem;
}
.form-group .form-control {
    font-size: 14px;
}
.form-check-inline {
    width: 20%;
    font-weight: 500;
}
.form-check-inline .form-check-input {
    margin-right: 10px;
}
label {
    font-size: 12px;
}
.submit-button {
    background-color: #003246!important;
    border: none;
    border-radius: 0px;
    width: 100%;
    padding: 10px;
    font-size: 14px;
}
.submit-button:hover {
    background-color: #003246;
}
.signin-submit-btn {
    margin-top: 55px;
}
.sign-txt {
    font-weight: 500;
    /* color: #003246; */
}
#text-bottom {
    font-weight: 450;
}
.radio-lab {
    margin-bottom: 0.8rem;
}

@media only screen and (max-width: 1450px) {
    .close-a img {
        width: 15px;
        vertical-align: initial;
    }
    .close-a {
        display: block;
    }
    #main-form {
        padding: 18px 30px 30px 30px;
        top: 51.5%;
    }
    #main-form h2 {
        margin-bottom: 16px;
        font-size: 28px;
    }
    label {
        font-size: 10px;
        margin-bottom: .3rem;
    }
    .form-group .form-control {
        font-size: 12px;
    }
    .form-group {
        margin-bottom: 0.5rem;
    }
    #radio-div {
        margin-bottom: 1rem;
    }
    .submit-button {
        font-size: 12px;
    }
    #text-bottom {
        font-size: 12px;
    }
  }

@media only screen and (max-width: 1070px) {
    #main-form {
        width: 42%;
    }
  }

  @media only screen and (max-width: 993px) {
    body {
        padding: 0px 3% 0px 3%;
    }
    .logo-img {
        width: 12%;
    }
    .form-div {
        background-size: 53%;
    }
    #main-form {
        width: 45%;
    }
    .form-check-inline {
        width: 18%;
    }
  }



  @font-face {
    font-family: 'helvetica bold';
    src: url(../../assets//fonts//Helvetica-Bold.ttf);
    font-weight: bold;
} 

@font-face {
    font-family: 'helvetica bolder';
    src: url(../../assets//fonts//Helvetica-Black.otf);
    font-weight: bolder;
}
@font-face {
    font-family: helvetica;
    src: url(../../assets/fonts/Helvetica-Condensed-Light-Light.ttf);
    font-weight: normal;
}
body {
    font-family: 'helvetica' 'helvetica bold' 'helvetica bolder';
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.login-form-div {
   
    background-repeat: no-repeat;
    background-size: 54%;
    background-position: left;
    background-position-y: 42%;
    height: calc(100vh - 90px);
    position: relative;
    margin: 30px;
    margin-top: 60px;
  }
  .login-form-div :focus {
    outline: 4px dashed orange;
  }
  .password-pages :focus {
    outline: 4px dashed orange;
  }
  .imageStyleLogin{
      margin-top: 2000 px;
  }
  /* #styleFormGroup{
      margin-top: .300 px;
  } */