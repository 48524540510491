/*header*/
@font-face {
	font-family: 'helvetica bold';
	src: url(../fonts//Helvetica-Bold.ttf);
	font-weight: bold;
} 

header .navbar {
	padding: 0px 0px;
}
header .navbar-brand img {
	max-width: 100px
}
header .navbar-menu-sec {
	justify-content: flex-end;
}
header .navbar-menu-sec .navbar-nav {
	align-items: center;
}
header .nav-item .btn-group button {
	background-color: transparent;
	font-family: inherit;
}
header .nav-item .btn-group .dropdown-toggle::after {
	display: none;
   
    
}
header .nav-item .btn-group button img {
	max-width: 30px;
}
header .navbar-menu-sec .navbar-nav .nav-link {
	color: #2E2E2E;
	font-size: 12px;
	padding: 8px 15px;
    font-family: "helvetica bold";
}
header .navbar-menu-sec .navbar-nav .nav-item {
	position: relative;
}
header .navbar-menu-sec .navbar-nav .nav-item.active .nav-link {
	color: #003246;
}
header .navbar-menu-sec .navbar-nav .nav-item.active:after {
	content: '';
	position: absolute;
	bottom: 0px;
	left: 50%;
	transform: translateX(-50%);
	height: 6px;
    width: 6px;
	border-radius: 50%;
	background-color: #003246;
}
header .notification-ico img{
	max-width: 13px;
}
header .notification-ico a {
	position: relative;
}
header .notification-ico a:after {
	content: '';
    height: 8px;
    width: 8px;
    border-radius: 50%;
    position: absolute;
    top: 10px;
    right: 14px;
    background: #007EA6;
    border: 2px solid #FFF;
}
header .notification-ico.hidden a:after {
	display: none;
}
.dropdown-menu dropdown-menu-right.dropdown-item{
    font-size: 11px;
}
.notify-dropdown .top-div {
    padding: 15px 20px 15px 20px;
    width: 550px;
}
.notify-dropdown {
    width: 550px!important;
}
.notify-dropdown .top-div h5 {
    font-size: 18px;
}
.notify-dropdown .top-div span {
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
}
.notify-dropdown .new-item {
    background: #CCE5ED!important;
}
.notify-dropdown .dropdown-item {
    white-space: normal!important;
    color: black!important;
    font-size: 14px;
    padding: .5rem 1.5rem;
}
.notify-dropdown .dropdown-item .head{
    color: #007EA6!important;
}
.notify-dropdown .dropdown-item p.float-right {
   line-height: 0px;
}
.notify-dropdown .dropdown-item p.float-right.inactive {
    color: rgba(255, 0, 0, 0.692)!important;
 }
header .navbar-menu-sec .navbar-nav .nav-item .btn-group > button {
	padding-right: 0;
}
.form-section-header {
	margin: 10px 0;
}
.form-section-header .form-group {
	margin: 0
}
.form-section-header .form-group:first-child,
.form-section-header .form-group:nth-child(3) {
	width: 15%;
}
.form-section-header .form-group:nth-child(2) {
	width: 24%;
}
.form-section-header .form-group:nth-child(4) {
	width: 30%;
}
.form-section-header .form-group:last-child {
	width: 16%;
}
.form-section-header .form-group .form-control, .form-section-header .btn-search {
	border-radius: 0;
	font-size:14px;
	height: 100%;
	color: #2E2E2E;
}
.form-section-header .form-group:first-child .form-control {
	border-top-left-radius: 3px;
	border-bottom-left-radius: 3px;
}
.form-section-header .form-group:last-child .btn-search {
	border-top-right-radius: 3px;
	border-bottom-right-radius: 3px;
}

.form-section-header .btn-search {
	width: 100%;
    border-radius: 0;
    background: #003246;
    color: #FFF;
}
.header-menu-icon {
    height: 32px;
    width: 30px;
    margin: 0 7px;
   background-repeat: no-repeat;
   background-position: center center;
   transition: 0.35s background;

}
.header-menu-icon:hover, .static-header-wrap li.active a{
    border-radius: 50%;
    background-color: #007EA6;
}


.search-icon {
    background-image: url('../../images/Search_Document.png');
    background-size: 18px;
}

.setting-icon {
    background-image: url('../../images/settings.png');
    background-size: 18px;
}


.static-header-wrap li.active .search-icon, .search-icon:hover {
    background-image: url('../../images/Search_DocumentW.png');
}

.static-header-wrap li.active .setting-icon, .setting-icon:hover {
    background-image: url('../../images/settings-white.png');
}

header .static-header-wrap ul li {
    display: inline-block;
    vertical-align: middle;
}
header .dropdown-menu.dropdown-menu-right.show {
    left: auto;
    right: 0;
}
header .navbar-menu-sec {
    position: relative;
}
header .navbar-menu-sec:after {
    content: '';
    height: 13px;
    width: 1px;
    background-color: #EDEDED;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
}
.grade-icon {
   max-width: 18px;
   margin-right: 8px;
}
/*  */
header .nav-item  .dropdown-toggle::after {
	display: none;
   
    
}

.navbar, .main-div {
    padding: 5px 2.5rem;
}
.manage-li {
    margin: 0px 20px; 
}
.manage-li img {
    vertical-align: middle;
    margin-left: 6px;
}
.manage-li.show img {
    transform: rotate(180deg);
}
.manage-li .nav-link {
    font-weight: bold;
    color: #003246!important;
}
.manage-li .dropdown-item {
    border-bottom: 1px solid rgba(172, 168, 168, 0.233)!important;
    padding: 8px 40px 8px 8px;
    color: black!important;
    font-size: 14px;
}
.manage-li .dropdown-item:hover {
   background: #CCE5ED;
}
.notify-dropdown {
    min-width: 430px;
 }
 .notify-dropdown .top-div {
    padding: 10px 20px 5px 20px;
 }
 .notify-dropdown .dropdown-item {
     white-space: normal!important;
     color: black!important;
     font-size: 14px;
     padding: .5rem 1.5rem;
 }
 .notify-dropdown .dropdown-item:hover {
    background: #CCE5ED!important;
}
.notify-dropdown .new-item {
    background: #CCE5ED!important;
}
 .notify-dropdown .dropdown-item img {
    vertical-align: inherit;
    width: 54px;
    margin: 0px 20px 0px 0px!important;
}
.notify-dropdown .view-text {
    color: #1387AC!important;
    font-weight: 700;
    font-size: 14px;
}
.notify-span {
    width: 80%;
    display: inline-block;
}
 .notify-span span {
     color: #1387AC!important;
 }

.head-txt span{
    z-index: 100000;
    position: absolute;
    left: 0;
}
.grp-img3 {
    width: 50px;
    position: absolute;
    left: 355px;
    top: 4px;
}

.main-div h5 {
    font-size: 14px;
}
.main-div select {
    text-transform: none;
    padding: 4px 120px 4px 4px;
    border-color: rgba(0, 0, 0, 0.301);
}
.main-div table thead {
    background-color: #EEF0F0;
    /* background-color: #dbe0eb; */
}
.table-striped tbody tr:nth-of-type(odd) {
    background-color: transparent;
}
.table-striped tbody tr:nth-of-type(even) {
    background-color: #F6F8F8;
    /* background-color:#E1E6EF; */
}
.main-div table {
    border: 1px solid #EEF0F0;
    border-radius: 5px;
}
.main-div table thead th {
    padding: 18px 10px;
    width: 10%;
    vertical-align: middle;
}
.main-div table thead th, .main-div table tbody td {
    text-align: center;
}

.main-div table thead th, .main-div table tbody td, .main-div table tbody th {
    font-size: 14px;
    border-right: 1px solid rgba(128, 128, 128, 0.377);
}

.main-div table thead .th-first {
    text-align: left;
    width: 20%!important;
}
.main-div table thead input {
    vertical-align: middle;
}
.main-div table th {
    font-weight: 500!important;
}

.main-div table .td-first {
    font-weight: bold!important;
}

.main-div table tbody .td-first input {
    float: left;
    margin-top: 6px;
}
.main-div table tbody .td-first span {
    float: left;
    width: 80%;
}

.main-div table thead .t-input {
    vertical-align: bottom;
}

.main-bottom button {
    /* padding: 10px 100px; */
    background: #003246;
    border: none;
    border-radius: 3px;
    font-weight: 500;
    font-size: 12px;
    /* text-transform: uppercase; */
}
.main-bottom button:hover {
    background: #003246!important;
}
.cancek-btn {
    color: #003246!important;
    background: transparent!important;
    border: 1px solid rgba(128, 128, 128, 0.521)!important;
    /* margin-left: 20px; */
}
.cancek-btn:hover, .close-modal-btn:hover {
    background: #003246!important;
    color: white!important;
}
.main-footer {
    padding: 10px 2.5rem;
    height: 82px;
    /* background: url(../images/Group\ 1763.png), #007BA3; */
    background-size: contain;
    background-repeat: no-repeat;
    margin: 0px!important;
}
.main-footer p {
    color: white;
    position: absolute;
    right: 2.5rem;
    top: 2rem;
    font-size: 12px;
    font-weight: 450;
}
.grp-img5 {
    width: 24px;
}
.reset-btn {
    font-size: 12px;
    text-transform: uppercase;
    color: #1387AC;
    position: absolute;
    right: 0;
    bottom: 8.6px;
    font-weight: bolder!important;
}
.close-a {
    position: absolute;
    right: 0;
    top: -20px;
}
.modal {
    /* z-index: 100000000000000; */
    z-index:1050;
}
.nav-link.dropdown-toggle::after {
    display: none!important;
}
.nav-popover {
    position: absolute;
    z-index: 1;
    background-color: #cdcdcd;
}
.nav-popover-button {
    border: none;
    background-color: #cdcdcd;
}
.top-btns-div {
    margin: 0px 60px 0px 0px!important;
    float: right;
}
.top-btns-div button {
    padding: 6.7px 60px;
}

.roles-top button, .staff-top button {
    border-top-left-radius: .25rem!important;
    border-bottom-left-radius: .25rem!important;
    padding: 10px 35px;
}
/* .audit-top-select {
    width: 225px;
    padding: 6px 6px 6px 6px!important;
    border: 1px solid #ced4da!important;
} */
.staff-top {
    width: 380px!important;
}
.school-top {
    width: 400px!important; 
}
.student-top {
    width: 406px!important;
}
.audit-table .active-status {
    color: #18B8A2!important;
}
.audit-table .inactive-status {
    color: #FF6767!important
}

.audit-table .p-tbody td,.audit-table .p-tbody th,.audit-table .p-thead th {
    text-align: left;
    padding: 0.75rem 1.5rem;
}
.audit-table .p-tbody th{
    font-weight: 550!important;
}
.audit-table .p-thead th {
    padding: 1.5rem 1.5rem;
    border-top: 0px;
}

.roles-top {
    width: 244px;
}


.audit-table .p-thead th:last-child, .audit-table .p-tbody td:last-child {
    border-right: 0px!important;
}

.staff-modal label,.student-modal label {
    color: black!important;
    font-weight: normal!important;
}
.school-modal .modal-dialog, .tenant-modal .modal-dialog, .student-modal .modal-dialog {
    width: 50%!important;
}
.school-modal .form-inpit-div,.tenant-modal .form-inpit-div,.student-modal .form-inpit-div {
    padding-right: 30px!important;
}
.sch-logo-img {
    left: 35px!important;
    top: 68px!important;
}
/* @media only screen and (max-width: 930px) {
    .audit-edit-top {
        position: initial;
        display: block!important;
        margin-top: 10px;
    }
} */
.form-inpit-div input:focus, .form-inpit-div textarea:focus{
    border-color: #003246!important;
}
@media only screen and (max-width: 1325px) {
    .student-modal .modal-dialog {
        width: 75%!important;
    }
}
/* @media only screen and (max-width: 830px) {
    .audit-top-select {
        margin-top: 10px;
    }
} */
.switch {
    position: relative;
    display: inline-block;
    width: 28px;
    height: 12px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #BFC0C0;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: -4px;
    bottom: -2.5px;
    background-color: #F1F1F1;
    -webkit-transition: .4s;
    transition: .4s;
  }
  
  input:checked + .slider {
    background-color: #7F98A2;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px #7F98A2;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(18px);
    -ms-transform: translateX(18px);
    transform: translateX(18px);
    background-color: #003246;
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  .grp-img3.staff {
    width: 75px;
    position: absolute;
    left: 170px;
    top: -10px;
}
.grp-img3.school {
    width: 75px;
    position: absolute;
    left: 198px;
    top: -10px;
}

/* manageuser ... */
/* .input-group {
    position: relative;
    display:flex;
    align-items: stretch;
  }
  .input-group>.custom-select:not(:last-child), .input-group>.form-control:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  } */
  .mb-2{
    margin-bottom: .5rem!important;
  }
  .flex-nowrap {
    -ms-flex-wrap: nowrap!important;
    flex-wrap: nowrap!important;
  }
  .color{
    color:red;
    font-size: 12px;
  }
  .staff-modal form {
    padding: 20px;
    border: 1px solid #d6d2da;
    margin: 15px;
    border-radius: 5px;
    max-height: 70vh;
    overflow: auto;
    font-family: Helvetica;
  }
  .staff-body .table-radio {
    height: 15px;
    font-family:Helvetica
  }
  .staff-modal input {
    border-radius: 5px;
    font-family:Helvetica
  }
  .staff-modal select {
    border-radius: 5px;
    font-family:Helvetica
  }
  .staff-modal textarea {
    border-radius: 5px;
    font-family:Helvetica
  }
  .staff-modal .sub-heading {
    font-size: 13px;
    font-weight: 700;
    font-family:Helvetica
  }
  .staff-body label {
    font-size: 13px !important;
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #0982A9;
    font-weight: 600;
    font-family:Helvetica
  }
  .staff-modal form label{
    font-size: 13px;
    font-family:Helvetica
  }
  .custom-select-filter {
    width: 50%;
    font-size:13px;

  }
  
  .custom-select-filter .css-g1d714-ValueContainer{
      height: 41.5px;
    max-height: 42px;
  }
  /* .staff-modal form input[type=radio] {
    min-height: 15px;
    font-family:Helvetica
  } */
  .close-staff-close {
    margin-top:22px;
    margin-right:26px;
    color:black;
    border:none;
    background-color: white;
    cursor:pointer;
    line-height:18px;
    font-size: 25px;
    font-weight: lighter;
  }
  .btn-secondary6{
    color: #fff;
    background: #003246;
    border-radius:0px;
    height:42.5px;
  }
  
  

  
  /* modal new */
  .health-form-modal.vision-modal-content {
    height: 100vh;
    overflow: auto;
    padding: 0px !important;
  }
  .health-form-modal .modal-header .custheader {
    border-bottom: 1px solid rgb(0 0 0 / 6%) !important;
  }
  .load-spinner {
    margin: 100px auto;
    display: block;
  }
  .text-danger{
    font-size:17px;
  }
  .health-form-modal .modal-dialog {
    max-width: 95%;
    width: 85%;
    margin: 0px 0px 0px auto;
}
.health-form-modal .modal-content {
    border-radius: 0px;
    height: 100vh;
    padding: 20px;
}
.health-form-modal .modal-header {
    border: none;
}
.health-form-modal .modal-header h5 {
    border: none;
    font-size: 24px;
    padding-top: 13px;
}
.modal .modal-title{
    font-size: 20px;
}
.health-form-modal .modal-header .btn-close {
    position: absolute;
    top: 25px;
    right: 40px;
}

.health-form-modal .form-footer {
    margin-top: 80px;
}
.health-form-modal .form-footer button {
    background: #003246;
    border: none;
    border-radius: 3px;
    padding: 8px 58px;
    font-size: 12px;
    margin-right: 20px;
    border: 1px solid #003246;
}



.health-form-modal .form-footer .cancel-button {
    color: #003246;
    background: transparent;
    transition: all 0.4s ease;
}
.health-form-modal .form-footer .cancel-button:hover {
    background: #003246;
    color: white;
}
.health-form-modal .form-footer button:focus, .health-form-modal select:focus,.health-form-modal textarea:focus {
    box-shadow: none!important;
}
.health-form-modal .concern-ul li {
    list-style-type: none;
    display: inline-block;
    padding: 2px 18px;
    font-size: 13px;
    border-radius: 15px;
    color: white;
    background-color: #bababa;
    margin-right: 10px;
    margin-bottom: 10px;
}
.health-form-modal .main-ul li {
    list-style-type: none;
    display: inline-block;
    width: 49%;
    padding-right: 10px;
    font-size: 14px;
}
.health-view-modal .form-footer {
    margin-top: 40px;
}

@media only screen and (max-width: 1700px) {
    .health-form-modal .checkbox-check.w-33 {
        width: 45%;
    }
    /* .form-details-div p {
        width: 32%;
    }
    .form-details-div .wid-50 {
        width: 64%;
    } */
    .parent-modal .form-details-div p {
        width: 140px;
    }
}
@media only screen and (max-width: 1450px) {
    .health-form-modal .modal-header h5 {
        padding-top: 0px;
    }
    .health-form-modal .modal-dialog {
        max-width: 95%;
        width: 90%;
    }
    .health-form-modal .form-div .top-details p:nth-child(2) {
        margin-left: 0px;
    }
    .health-form-modal .form-div .top-details p, .form-details-div p {
        font-size: 14px;
    }
    .health-form-modal .form-inpit-div {
        padding-right: 30px;
    }
    .health-form-modal .form-inpit-div .close-icon {
        right: 12px;
    }
    .health-form-modal .form-inpit-div input {
        padding: 0.45rem .75rem;
    }
    .health-form-modal .form-footer {
        margin-top: 50px;
    }
    .health-view-modal .form-footer {
        margin-top: 20px;
    }
    .folder-img {
        left: 24px;
        top: 40px;
    }
    .parent-modal .modal-dialog {
        width: 55%;
    }
}
/* 
@media only screen and (max-width: 1150px) {
    .time-details-div .wid-100  {
        width: 100%!important;
    }
    .time-details-div .w-25  {
        width: 50%!important;
    }
    .form-details-div p {
        width: 45%;
    }
    .parent-modal .comments-box {
        width: 100%;
    }
} */
@media only screen and (max-width: 1050px) {
    .health-form-modal .form-footer button {
        margin-bottom: 10px;
        padding: 8px 38px;
    }
    .health-form-modal .form-inpit-div.w-25 {
        width: 50%!important;
    }
}
@media only screen and (max-width: 993px) {
    .health-form-modal {
        width: 100%!important;
    }
    .health-form-modal {
        width: 100%!important;
    }
   
}
/* @media only screen and (max-width: 900px) {
    .form-details-div p {
        width: 100%!important;
    }
} */
.filtersearch{
    margin-left:690px;
    margin-top:0px;
    position:relative;
    z-index:2;
    width:280px;
  }
  .addstaffsearch-button{
     
    width:190px;
    font-size: 1rem;
    height:39px;
    background: #003246;
    font-size:13px;
    font-family:Helvetica;
    color:white;
  }
  .staffsearch-button{
    position: relative;
    height:39px;
    width:190px;
    background: #003246;
    font-size:13px;
    font-family:Helvetica;
    color:white;
    cursor: pointer;
  }
  .form-groupstaffnumber{
    position: relative;
    padding-left:8px;
    padding-top:4px;
    height:38px;
    width:374px;
    border:1px solid lightgray;
    font-size:13px;
    cursor: pointer;
    font-family:Helvetica;
    color:black;
    font-weight:548!important;
  }
  .custom-select-filterstaff .css-1okebmr-indicatorSeparator{
    width:0px;
    }
    .custom-select-filterstaff {
        font-size:13px;
        font-family:Helvetica;
        border:1px thin lightgray;
      }
      .custom-select-filterstaff .css-yk16xz-control {
          
        height:12px;
        border-radius: 0;
        box-shadow:none;
        outline:none;
      }
      .custom-select-filterstaff .css-g1d714-ValueContainer{
        padding-left:4px;
        max-height: 40px;
        font-family: Helvetica;
      }
      .custom-select-filterstaff .css-1wa3eu0-placeholder{
        color:black;
      }
      .custom-select-filterstaff .css-tj5bde-Svg{
        color:black;
        width:16px;
      }
      .custom-select-filterstaff .css-1pahdxg-control{
        outline:none;
        border-radius:0px;
        border:1px thin;
        border-color:lightgrey;
        align-content: center;
        box-shadow: none;
        height:6px;
      }
 
.audit-wrap {
    border: 1px solid #dce4f2;
    border-radius: 5px;
    padding: 0;
  }
  .audit-wrap table{
    border-collapse: collapse;
    vertical-align: middle;
  }