.transition-1 {
    transition: all 0.33s ease;
    -webkit-transition: all 0.33s ease;
    -moz-transition: all 0.33s ease;
  }
  
  .transition-2 {
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
  }