.modalZIndex {
  z-index: 99999 !important;
}

/*-------------dynamic form builder css  ------*/
.overlay {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.6);
  overflow-x: hidden;
  transition: 0.5s;
}
.overlay-content {
  width: 50%;
  min-height: 100vh;
  background: #fff;
  float: right;
}

.overlay-content input[type="file"] {
  cursor: pointer;
  width: auto;
  height: auto;
  overflow: hidden;
}

.overlay-content input[type="file"]:before {
  width: auto;
  height: auto;
  padding: 10px;
}

.dynamic-form-container input[type="file"] {
  cursor: pointer;
  width: auto;
  height: auto;
  overflow: hidden;
}

.new-filed-container .delete-student-img {
  width: 15px;
  height: 15px;
  margin-top: 15px;
}

.dynamic-form-container input[type="file"]:before {
  width: auto;
  height: auto;
  padding: 10px;
}

.overlay .closebtn {
  position: absolute;
  top: 20px;
  right: 45px;
  font-size: 25px;
  cursor: pointer;
}

.overlay .form-container {
  width: 90%;
  margin: 10px auto;
  padding: 10px;
}

.overlay .form-content {
  border: 1px solid #f9efef;
  padding: 15px;
  border-radius: 5px;
}

.new-filed-container {
  border: 0.5px solid #e4e4ea;
  border-radius: 5px;
  padding: 0 10px;
  margin: 10px 0;
}

.bottom-line-input {
  outline: 0;
  border-width: 0px 0 1px;
  border-radius: 0;
  border-color: #e4e4ea;
}
.new-input-left {
  width: 10vw;
}
.new-input-right {
  width: 90vw;
}
.multi-select .dropdown-heading {
  max-height: 30px;
}

/* dynamicForm */
.dynamicForm {
  padding: 20px;
}
.dynamicForm .dynamic-form-header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
}
.dynamicForm .dynamic-form-header h1 {
  font-size: 20px;
  font-weight: 600;
  -webkit-text-stroke-width: thin;
  color: #333;
}
.dynamic-form-div {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 20px 30px;
  margin-top: 20px;
}
.dynamic-form-div .header {
  position: relative;
}
.dynamic-form-div .header p {
  font-size: 14px;
  color: #666;
  font-weight: 500;
  position: relative;
}
.form-filter {
  display: flex;
  justify-content: space-between;
}
.filter-div form {
  width: 100%;
}
.filter-div form .form-group {
  width: 20%;
}
.filter-div form .form-group select {
  width: 90%;
}
.filter-div button {
  color: #fff;
  border: 0px;
  background: #003246;
  padding: 4px 20px;
  border-radius: 1px;
  font-size: 13.5px;
  white-space: nowrap;
  font-weight: 500;
  /* text-transform: uppercase; */
  -webkit-text-stroke-width: thin;
}
.filter-div p {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 5px;
  -webkit-text-stroke-width: thin;
}
.filter-div button span {
  font-size: 18px;
}
.dynamic-form-div ul li div {
  position: relative;
  padding: 19px 23px;
  /* border-bottom: 1px solid #f1f1f1; */
}
.dynamic-form-div ul li {
  list-style-type: none;
}
.dynamic-form-div ul li p {
  color: #171717;
  font-size: 14px;
  padding-left: 19px;
  margin-bottom: 6px;
}
.dynamic-form-div ul li p:first-child {
  color: #007ea6;
  font-weight: 600;
  font-size: 18px;
  padding-left: 0px;
  -webkit-text-stroke-width: thin;
  margin-bottom: 8px;
}
.dynamic-form-div ul li p:first-child span.active,
.dynamic-form-div ul li p:first-child span.inactive {
  color: #44a882;
  background: #def8ee;
  text-align: center;
  padding: 5px 14px;
  font-weight: 500;
  font-size: 13.5px;
  border-radius: 20px;
  margin-left: 10px;
  -webkit-text-stroke-width: initial;
}
.dynamic-form-div ul li p:first-child span.inactive {
  background: #f1f1f1;
  color: #666;
}
.dynamic-form-div ul li p {
  color: #ddd;
}
.dynamic-form-div ul li p span {
  position: relative;
  color: #666;
  font-size: 14px;
  -webkit-text-stroke-width: thin;
}
.dynamic-form-div ul li p span.calendar {
  padding-left: 25px;
}
.dynamic-form-div ul li p span.nurse::before {
  content: "";
  position: absolute;
  background: url(../../images/noun_Nurse.png) center center no-repeat;
  left: -25px;
  top: 0;
  background-size: contain;
  width: 17px;
  height: 17px;
}
.dynamic-form-div ul li p span.calendar::before {
  content: "";
  position: absolute;
  background: url(../../images/noun_schedule.png) center center no-repeat;
  left: 3px;
  top: 0;

  background-size: contain;
  width: 17px;
  height: 17px;
}
.dynamic-form-div ul li p span.grade::before {
  content: "";
  position: absolute;
  left: -25px;
  top: 0;

  background-size: contain;
  width: 17px;
  height: 17px;
}
.dynamic-form-div ul li p:nth-child(3) {
  padding: 0px;
  margin: 0px;
  margin-top: 14px;
}
.dynamic-form-div ul li p:nth-child(3) span {
  padding: 5px 10px;
  border-radius: 20px;
  margin-right: 5px;
  background: #f1f1f1;
  font-size: 13px;
  -webkit-text-stroke-width: thin;
}
.dynamic-list {
  padding: 0px;
  display: flex;
  flex-direction: column-reverse;
}
.f-12 {
  font-size: 14px;
}
.f-none {
  float: none !important;
}
.dropdown-menu-more {
  align-items: right;
  background: url(../../images/more.png) center center no-repeat;
}
.preview-input {
  width: 45%;
  margin: 5px 15px;
}
.custom-class-img {
  width: 25px !important;
  cursor: pointer;
}
.custom-left {
  cursor: pointer;
  height: 12px;
}
.required-asterix {
  color: red;
  size: 10px;
}
.drop-more {
  position: absolute !important;
  transform: translate3d(-133px, 36px, 0px) !important;
  top: 0px !important;
  left: 0px !important;
  will-change: transform !important;
}
.drop-more-main {
  float: right;
  top: -80px;
  border-bottom: none !important;
}
.form-group .label-size {
  font-size: 11px;
}
.form-row .textAreaColor {
  color: #ff6767;
}
.div-cus {
  display: contents;
}
.select-cus {
  width: 21%;
  margin-left: 10px;
  margin-right: 10px;
}
.chk-cus {
  width: 33% !important;
}
.chk-cus2 {
  width: 9% !important;
}
.form-inline .react-datepicker__input-container{
  display: block;
  width: 100%;
  font-size: .875rem;
  font-weight: 400;
  color: #3c4e71;
  background-color: #fff;
  border: 1px solid #c9d2e3;
  border-radius: 6px;
}
.option-required {
  color: red;
}

.body-overflow {
  overflow: auto;
  height: 500px;
}

.margin-custom {
  margin: 0px !important;
}

.cursor-custom {
  cursor: pointer;
}

.file-custom {
  width: 52% !important;
}

.dynamicForm .datepicker {
  background: url(../../images/calendars.png) white no-repeat right 0.75rem
    center/8px 10px;
  background-size: 13px;
  background-position-y: 12px;
}

.dynamicFormMaster [type="radio"]:not(:checked) + label:before {
  border: 1px solid grey;
}

.dynamicFormMaster [type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.dynamicFormMaster [type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.dynamicFormMaster [type="radio"]:checked + label:after,
.dynamicFormMaster [type="radio"]:not(:checked) + label:after {
  content: "";
  width: 8px;
  height: 200px;
  background: #0982a9;
  border: 1px solid #0982a9;
  position: absolute;
  top: 6px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  visibility: hidden;
}

.dynamicFormMaster [type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  border: 1px solid #003246;
  border-radius: 100%;
  background: #fff;
  visibility: hidden;
}

.txt-area-custom {
  height: fit-content !important;
}
.label-custom {
  white-space: pre-wrap !important;
  font-size: 12px;
}
/*-------------dynamic form builder css ends ------*/

/*------------Dynamic form render start----------*/
.dynamic-form-container {
  width: 60%;
  margin: auto;
  border: 1px solid #f9efef;
  padding: 15px;
  border-radius: 5px;
  justify-content: center;
}
.dynamic-form-container .form-group {
  width: 45%;
  margin: 5px 15px;
}
.h-auto {
  height: auto;
}
/*------------Dynamic form render ends----------*/

.page {
  /*padding: 20px 30px;*/
  /* min-height: 100vh; */
  padding: 0px 30px;
}

/* dynamicForm */
.dynamicForm {
  padding: 20px;
}
.dynamicForm .dynamic-form-header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
}
.dynamicForm .dynamic-form-header h1 {
  font-size: 19px;
  -webkit-text-stroke-width: thin;
  color: #333;
}
.dynamic-form-div {
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 20px 30px;
  margin-top: 20px;
}
.dynamic-form-div .header {
  position: relative;
}
.dynamic-form-div .header p {
  font-size: 14px;
  color: #666;
  font-weight: 500;
  position: relative;
}
.form-filter {
  display: flex;
  justify-content: space-between;
}
.filter-div form {
  width: 100%;
}
.filter-div form .form-group {
  width: 20%;
}
.filter-div form .form-group select {
  width: 90%;
}
.filter-div button {
  color: #fff;
  border: 0px;
  background: #003246;
  padding: 4px 20px;
  border-radius: 1px;
  font-size: 13px;
  white-space: nowrap;
  font-weight: 500;
 /* // text-transform: uppercase; */
}
.filter-div p {
  font-size: 14px;
  margin-bottom: 5px;
  -webkit-text-stroke-width: thin;
}
.filter-div button span {
  font-size: 18px;
}
.dynamic-form-div ul li > div {
  position: relative;
  padding: 19px 0px;
  /* border-bottom: 1px solid #f1f1f1; */
}
.dynamic-form-div ul li {
  list-style-type: none;
}
.dynamic-form-div ul li p {
  color: #171717;
  font-size: 14px;
  padding-left: 2px;
  margin-bottom: -20px;
}
.dynamic-form-div ul li p:first-child {
  color: #007ea6;
  font-size: 17px;
  padding-left: 0px;
  -webkit-text-stroke-width: thin;
  margin-bottom: 8px;
}
.dynamic-form-div ul li p:first-child span.active,
.dynamic-form-div ul li p:first-child span.inactive {
  color: #44a882;
  background: #def8ee;
  text-align: center;
  padding: 5px 14px;
  font-weight: 500;
  font-size: 13.5px;
  border-radius: 20px;
  margin-left: 10px;
  -webkit-text-stroke-width: initial;
}
.dynamic-form-div ul li p:first-child span.inactive {
  background: #f1f1f1;
  color: #666;
}
.dynamic-form-div ul li p {
  color: #ddd;
}
.dynamic-form-div ul li p span {
  position: relative;
  color: #666;
  font-size: 14px;
}
.dynamic-form-div ul li p span.calendar {
  padding-left: 25px;
}
.dynamic-form-div ul li p span.nurse::before {
  content: "";
  position: absolute;
  left: -25px;
  top: 0;
  background: url(../../images/noun_Nurse.png) center center no-repeat;
  background-size: contain;
  width: 17px;
  height: 17px;
}
.dynamic-form-div ul li p span.calendar::before {
  content: "";
  position: absolute;
  left: 3px;
  top: 0;
  background: url(../../images/noun_schedule.png) center center no-repeat;
  background-size: contain;
  width: 17px;
  height: 17px;
}
.dynamic-form-div ul li p span.grade::before {
  content: "";
  position: absolute;
  left: -25px;
  top: 0;
  background: url(../../images/grade.png) center center no-repeat;
  background-size: contain;
  width: 17px;
  height: 17px;
}
.dynamic-form-div ul li p:nth-child(3) {
  padding: 0px;
  margin: 0px;
  margin-top: 14px;
}
.dynamic-form-div ul li p:nth-child(3) span {
  padding: 5px 10px;
  border-radius: 20px;
  margin-right: 5px;
  background: #f1f1f1;
  font-size: 13px;
}

.simple-pagination li a {
  margin-top: 20px;
}
.simple-pagination li:first-child a,
.simple-pagination li:last-child a {
  padding: 15px 10px;
  margin-top: 0px;
}
.simple-pagination li:last-child::after {
  content: "";
  position: absolute;
  right: 3px;
  top: 2px;
  background: url(../../images/R.png) left top no-repeat;
  background-size: contain;
  width: 12px;
  height: 17px;
  pointer-events: none;
}
.simple-pagination li:first-child::before {
  content: "";
  position: absolute;
  left: 3px;
  top: 2px;
  background: url(../../images/le-l.png) left top no-repeat;
  background-size: contain;
  width: 12px;
  height: 17px;
  pointer-events: none;
}

.dynamicModal .modal-dialog {
  max-width: 65% !important;
  width: 50% !important;
  margin: 0px 0px 0px auto;
}
#audienceFormModal .modal-dialog {
  max-width: 65% !important;
  width: 56% !important;
  margin: 0px 0px 0px auto;
}
.edit-form {
  position: relative;
  font-size: 13px;
  margin: 0px;
  margin-top: 12px;
}
.dynamicModal .modal-header {
  border: none;
  margin-top: 20px;
}
.edit-form::before {
  content: "";
  position: absolute;
  left: -20px;
  top: 2px;
  background: url(../../images/edit_clr.png) center center no-repeat;
  background-size: contain;
  width: 16px;
  height: 16px;
}

.dynamicModal .modal-body .form-div::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #fff;
}

.dynamicModal .modal-body .form-div::-webkit-scrollbar {
  width: 8px;
  background-color: #fff;
}
.w-20 {
  width: 20%;
}
.dynamic-view1 {
  width: 16px;
  height: 13px;
  object-fit: scale-down;
}
.dynamicModal .modal-body .form-div::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #ddd;
}
.dynamic-view {
  width: 26px;
  height: 26px;
  object-fit: scale-down;
}
.section-title mt-4{
  font-size: 1.45rem;
  margin: 0;
  font-weight: 600;
}
.section-title {
  font-size: 1.45rem;
  margin: 0;
  font-weight: 600;
}
.section-title-big {
  font-size: 18px;
}
@media (min-width: 1500px) and (max-width: 1800px) {
  .contact_us .pink_blue_tick {
    background-position: 18%;
  }
  .close-a {
    right: 5%;
  }
}
@media (min-width: 1801px) {
  .contact_us .pink_blue_tick {
    background-position: 16%;
  }
  .close-a {
    right: 5%;
  }
  .contact_us .contact_details .map {
    margin-top: 1.8rem;
  }
}
@media screen and (max-width: 991px) {
  .container-main {
    flex-wrap: wrap;
  }
  .column-1 {
    width: 40%;
  }
  .column-2 {
    width: 60%;
  }
  .column-3 {
    width: 100%;
    display: flex;
  }
  .column-3 .box-content:first-child {
    width: 40%;
  }
  .column-3 .box-content:last-child {
    width: 60%;
  }
  .column-3 .box-content #parentRequestAccordion {
    margin-left: 20px;
  }
  .column-2 .box-content.mx-4 {
    margin-right: 0 !important;
  }
  .column-3 .box-content .border-bottom {
    border-bottom: 0 !important;
  }

  /*modal*/
  .single-column .modal-dialog {
    width: 90%;
  }
  .dynamic-modal-form .modal-header h5.modal-title {
    font-size: 16px;
    color: #171717;
  }

  .covid-details-wrap .covid-details-list:nth-child(2),
  .covid-details-wrap .covid-details-list:last-child {
    width: 30%;
  }

  .covid-page .main-chart {
    margin: 0 auto;
    display: block;
    text-align: center;
    height: 150px;
  }
  .covid-page .sub-charts {
    width: 100%;
    text-align: center;
    display: block;
  }
  .covid-page .main-chart:after {
    display: none;
  }
  .covid-page .main-chart .chart-lagends {
    text-align: left;
  }

  .covid-page .main-chart .chart-lagends {
    width: auto;
  }
  .covid-page .sub-charts .chart-sec {
    width: 114px;
    height: 148px;
  }
  .covid-page .main-chart .circle-center {
    width: 75px;
    height: 75px;
  }
  .covid-page .sub-charts .circle-center {
    width: 64px;
    height: 64px;
  }

  .covid-page .sub-charts .chart-center-sec p {
    font-size: 11px;
  }

  .qr-code-wrap li {
    width: calc(33.33% - 20px);
  }
  .onload-search-wrap .circle-search-wrap {
    padding: 30px 30px;
  }
  .onload-search-wrap .circle-search-wrap img {
    max-width: 35px;
  }
}
.form-group {
  margin-bottom: 0.8rem;
}
@media only screen and (max-width: 1450px) {
  .close-a img {
    width: 15px;
    vertical-align: initial;
  }
  .close-a {
    display: block;
  }
  #main-form {
    padding: 18px 30px 30px 30px;
    top: 51.5%;
  }
  #main-form h2 {
    margin-bottom: 16px;
    font-size: 28px;
  }
  label {
    font-size: 10px;
    margin-bottom: 0.3rem;
  }
  .form-control {
    font-size: 12px;
  }
  .form-group {
    margin-bottom: 0.5rem;
  }
  #radio-div {
    margin-bottom: 1rem;
  }
  .submit-button {
    font-size: 12px;
  }
  #text-bottom {
    font-size: 12px;
  }
}
.main-bottom button {
  /* padding: 10px 100px; */
  background: #003246;
  border: none;
  border-radius: 3px;
  font-weight: 500;
  font-size: 12px;
  color:white;
  /* text-transform: uppercase; */
}
.main-bottom button:hover {
  background: #003246 !important;
}
.addSchoolbutton {
  color: #fff;
  background: #003246;
  border: none;
  border-radius: 3px;
  padding: 8px 21px;
  font-size: 12px;
  margin-right: 20px;
  border: 1px solid #003246;
  margin-left: 16px;
}
.filter-div form {
  width: 100%;
}
.filter-div form .form-group {
  width: 20%;
}
.filter-div form .form-group select {
  width: 90%;
}
.filter-div button {
  color: #fff;
  border: 0px;
  background: #003246;
  padding: 4px 20px;
  border-radius: 1px;
  font-size: 13px;
  white-space: nowrap;
  font-weight: 500;
  /* text-transform: uppercase; */
}
.filter-div p {
  font-size: 14px;
  margin-bottom: 5px;
  -webkit-text-stroke-width: thin;
}
.filter-div button span {
  font-size: 18px;
}
@media (max-width: 1100px) {
  .profile-right .row .col-5 {
    max-width: 100%;
    flex: 100%;
  }
  .profile-right .row .col-7 {
    max-width: 100%;
    flex: 100%;
  }
  .medicine-reorder .emergency-info,
  .medicine-reorder .emergency-info .immunization-content {
    display: block;
  }
  .add-chart a {
    font-size: 14px;
  }
  .profile-right.col-8 {
    max-width: 66%;
  }
  .profile-left.col-4 {
    max-width: 44%;
  }
  .type-list li {
    width: 50%;
  }
  .profile-card .profile-info ul li:nth-child(2) {
    width: 40%;
  }
  .profile-card .profile-info ul li p {
    font-size: 14px;
  }
  .studSchool img {
    width: 29px;
    height: 25px;
  }
  .studSchool {
    font-size: 13.5px;
  }
  .filter-div form .form-group {
    width: 33%;
  }
}
.more-btn {
  box-shadow: none;
  background: transparent;
  border: 0;
}
.more-btn.dropdown-toggle::after {
  display: none;
}
.dropdown-menu .dropdown-item {
  font-size: 13px;
}
.modal .modal-title {
  font-size: 20px;
}
.modal .modal-title {
  font-size: 20px;
}
.modal .date-fld {
  width: 100%;
  padding: 0.45rem 0.75rem;
  border: 1px solid #ddd;
  border-radius: 2px;
  height: auto;
}
.modal .ui-datepicker-trigger {
  margin-left: -35px;
}
.react-datepicker-wrapper {
    width: 100%;
  }

.modal textarea {
  resize: none;
  height: 80px;
}
.modal {
  padding-right: 0 !important;
}
.common-modal .modal-dialog {
  width: 50%;
  max-width: 95%;
  margin: 0px 0px 0px auto;
}
.common-modal .modal-content {
  border-radius: 0px;
  min-height: 100vh;
  padding: 20px;
}
.common-modal .form-right {
  width: 100%;
  padding-left: 0px;
}
.common-modal .form-div {
  box-shadow: 0px 0px 1px #14121281;
  padding: 15px;
  border-radius: 3px;
  max-height: 80vh;
  overflow: auto;
}
.common-modal .modal-header {
  border: none;
}
.common-modal .form-footer .cancel-button {
  color: #003246;
  background: transparent;
  transition: all 0.4s ease;
}
.common-modal .form-footer button {
  width: 160px;
  background: #003246;
  border: none;
  border-radius: 3px;
  padding: 10px 0px;
  font-size: 12px;
  margin-right: 20px;
  border: 1px solid #003246;
}
.common-modal .form-footer .cancel-button:hover {
  background: #003246;
  color: white;
}
.common-modal .print-btn {
  padding: 8px 68px;
}
.common-modal .form-inpit-div {
  width: 50%;
  float: left;
  padding-right: 50px;
  position: relative;
}
.common-modal .preview-div {
  position: absolute;
  width: 50%;
  left: 50%;
  top: 135px;
  transform: translateX(-50%);
}
.common-modal .preview-main {
  height: 65vh;
  position: relative;
}
.common-modal .preview-main .image-div {
  background: #f2f2f2;
  border-radius: 50%;
  width: 180px;
  height: 180px;
  margin: auto;
}
.common-modal .preview-main .detail-p {
  font-size: 14px;
}
.common-modal .disabled-btn {
  pointer-events: none;
}
.common-modal .preview-main img {
  width: 70px;
  position: absolute;
  left: 48%;
  transform: translateX(-40%);
  top: 45px;
}
.common-modal .datepicker {
  background: url(../../images/calendars.png) white no-repeat right 0.75rem
    center/8px 10px;
  background-size: 13px;
  background-position-y: 12px;
}
.common-modal .form-label {
  font-size: 13px !important;
}
.common-modal .form-inpit-div select {
  width: 100%;
  padding: 0.6rem 0.75rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  font-size: 14px;
  color: gray;
}
.common-modal textarea {
  resize: none;
  height: 80px;
}
.common-modal .dates-list li {
  display: inline-block;
  background-color: #cce5ed;
  padding: 1px 25px 1px 6px;
  margin: 8px 5px 2px 0px;
  border-radius: 10px;
  font-size: 13px;
  position: relative;
  /* background: url(../images/noun_Close_1808841.png) #CCE5ED no-repeat right .75rem center/8px 10px;
  background-size: 13px;
  background-position-y: 12px; */
}
.common-modal .w-100 {
  width: 100% !important;
}
.common-modal .w-25 {
  width: 25% !important;
}
.common-modal .close-img {
  width: 8px;
  position: absolute;
  right: 8px;
  top: 7px;
  cursor: pointer;
}
@media only screen and (max-width: 993px) {
  .common-modal .modal-dialog {
    width: 80%;
  }
}
.common-modal .modal-dialog {
  width: 50%;
  max-width: 95%;
  margin: 0px 0px 0px auto;
}
.common-modal .modal-content {
  border-radius: 0px;
  min-height: 100vh;
  padding: 20px;
}
.common-modal .form-right {
  width: 100%;
  padding-left: 0px;
}
.common-modal .form-div {
  box-shadow: 0px 0px 1px #14121281;
  padding: 15px;
  border-radius: 3px;
  max-height: 90vh;
  overflow: auto;
}
.common-modal .modal-header {
  border: none;
}
.common-modal .form-footer .cancel-button {
  color: #003246;
  background: transparent;
  transition: all 0.4s ease;
}
.common-modal .form-footer button {
  width: 160px;
  background: #003246;
  border: none;
  border-radius: 3px;
  padding: 10px 0px;
  font-size: 12px;
  margin-right: 20px;
  border: 1px solid #003246;
}
.common-modal .form-footer .cancel-button:hover {
  background: #003246;
  color: white;
}
.common-modal .print-btn {
  padding: 8px 68px;
}
.common-modal .form-inpit-div {
  width: 50%;
  float: left;
  padding-right: 50px;
  position: relative;
}
.common-modal .preview-div {
  position: absolute;
  width: 50%;
  left: 50%;
  top: 20%;
  transform: translateX(-50%);
}
.common-modal .preview-main {
  height: 65vh;
  position: relative;
}
.common-modal .preview-main .image-div {
  background: #f2f2f2;
  border-radius: 50%;
  width: 180px;
  height: 180px;
  margin: auto;
}
.common-modal .preview-main .detail-p {
  font-size: 14px;
}
.common-modal .disabled-btn {
  pointer-events: none;
}
.common-modal .preview-main img {
  width: 70px;
  position: absolute;
  left: 48%;
  transform: translateX(-40%);
  top: 45px;
}
.common-modal .datepicker {
  background: url(../../images/calendars.png) white no-repeat right 0.75rem
    center/8px 10px;
  background-size: 13px;
  background-position-y: 12px;
  cursor: pointer;
}
.common-modal .form-label {
  font-size: 13px !important;
}
.common-modal .form-inpit-div select {
  width: 100%;
  padding: 0.6rem 0.75rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  font-size: 14px;
  color: gray;
}
@media only screen and (max-width: 993px) {
  .common-modal .modal-dialog {
    width: 80%;
  }
}
@media (max-width: 746px) {
  .form-modal .modal-dialog {
    width: 100%;
    max-width: 100%;
  }
  .full-page-modal-right .modal-content {
    /* padding: 0px; */
    padding: 0px 20px;
  }
  .covid-tab .nav-tabs .nav-link.active::after {
    bottom: -5px;
  }
  .covidTask_table ul.covidTask-ul li {
    display: block;
  }
  .covidTask_table ul.covidTask-ul li div {
    display: inline-block;
    /* width: 47%; */
    margin: 0px;
    width: 32%;
  }
  .full-page-modal-right .modal-body .previous-log {
    height: auto;
  }
  .covid-contact ul li p.stud_name {
    font-size: 15px;
  }
  .full-page-modal-right .modal-header h5 {
    font-size: 17px;
  }
  .covidTask_table ul.covidTask-ul li p.date {
    display: inline-block;
    width: 100%;
    text-align: right;
  }
  .form-modal .form-inpit-div {
    padding-right: 0px;
  }
  .form-modal .form-inpit-div input.w-50 {
    width: 100% !important;
  }
  .form-modal .form-inpit-div {
    width: 100% !important;
  }
  .full-page-modal-right1 .modal-dialog {
    width: 100%;
    max-width: 100%;
  }
  #searchModal .modal-header {
    padding: 15px;
    margin-top: 20px;
  }
  .full-page-modal-right .modal-header .btn-close {
    right: 15px;
  }
  #addImmunizationModal .nav-tabs .nav-link {
    font-size: 14px;
    padding-right: 10px;
  }
  .common-modal .form-inpit-div {
    width: 100%;
    padding-right: 0px;
  }
  .common-modal .modal-dialog {
    width: 100%;
    max-width: 100%;
  }
  .common-modal .modal-content {
    padding: 0px;
  }
  .common-modal .modal-content .tab-content {
    padding: 0px;
  }
  .common-modal .form-footer button {
    width: 115px;
  }
  .ui-datepicker {
    width: 295px;
  }
  .immunization-main .label-p {
    font-size: 13px;
  }
  .immunization-ul li {
    width: 50%;
  }
  .common-modal .preview-main img {
    width: 50px;
    top: 92px;
  }
  .common-modal .preview-main .image-div {
    width: 130px;
    height: 130px;
  }
  .common-modal .preview-div {
    position: relative;
    width: 100%;
    left: auto;
    top: auto;
    transform: inherit;
    padding-top: 60px;
  }

  /* medication authorization */
  .form-modal .modal-content {
    height: auto;
  }
  .form-modal .modal-body {
    padding: 0px;
  }
  .form-modal .form-left,
  .form-modal .form-right {
    width: 100%;
    float: none;
    height: auto;
    padding: 0px;
  }
  .form-modal .modal-header {
    padding-left: 0px;
    padding-right: 0px;
  }
  .form-modal .form-left,
  .form-modal .form-right {
    height: 101vh;
  }
  .form-modal .form-right .form-div {
    max-height: 101vh;
    height: 101vh;
  }
  .scololiosisModal .add-screen {
    margin-top: 7px;
  }
  .form-modal .modal-header .btn-close {
    right: 7px;
    top: -7px;
  }
  .form-modal .modal-header h5 {
    overflow-wrap: anywhere;
  }
  .form-modal .form-inpit-div.w-25 {
    width: 50% !important;
  }
  #exampleModalFour .form-details-div p {
    width: 49% !important;
  }
  #exampleModalFour .form-details-div .wid-50 {
    width: 100% !important;
  }
  #exampleModalFour .form-details-div p.align-top {
    width: 94% !important;
  }
  .form-modal .form-inpit-div.pl-2 {
    padding-left: 0px !important;
  }
  .form-modal .form-inpit-div.pr-4 {
    padding-right: 0px !important;
  }
  .form-modal .modal-header h5 {
    font-size: 18px;
  }
  .file-list2 li > div > div {
    display: flex;
  }
  .scololiosisModal .file-list2 .view-details {
    display: none;
  }
  .scololiosisModal .file-desc-p.w-60,
  #visionModal .file-desc-p.w-60 {
    width: 100%;
  }
  .scololiosisModal .close-view .view,
  .scololiosisModal .close-view .view:hover {
    padding: 7px 12px;
    font-size: 11px;
  }
  .scololiosisModal .file-desc-p {
    font-size: 13px;
  }
  #visionModal .modal-content {
    padding: 20px;
  }
  .details-wrap-btns button:nth-child(even) {
    margin: 0px;
  }
  .details-wrap-btns button {
    padding: 7px;
    font-size: 13px;
  }
  #visionModal .files-row p {
    font-size: 13px;
  }
  .add-screen {
    margin-top: 5px;
  }
  .close-view {
    margin: 0px;
  }
  .close-view .view,
  .close-view .view:hover {
    font-size: 11px;
    padding: 7px 15px;
  }
  .form-modal .form-footer {
    margin-top: 30px !important;
  }
  .date-fld {
    width: 165px;
  }
  #visionModal .files-row p {
    margin: 0px;
    font-size: 13px;
  }
  small {
    font-size: 85%;
  }
  #addtreatmentModal .top-details p.ml-4,
  #addMARModal .top-details p.ml-4 {
    margin-left: 0px !important;
  }
  .form-modal .form-inpit-div.w-25 {
    width: 48% !important;
  }
  .form-modal .form-inpit-div.w-25:last-child,
  .form-modal .form-inpit-div.w-25.override-dosage {
    margin-left: 10px;
  }
  #medicationDoneModal .top-details p {
    margin-left: 33px !important;
  }
  #medicationDoneModal .top-details p:nth-child(1),
  #medicationDoneModal .top-details p:nth-child(3) {
    margin-left: 0px !important;
  }
  .form-modal .form-inpit-div {
    padding-left: 0px !important;
    padding-right: 10px !important;
  }
  .file-desc-p.w-50 {
    width: 100% !important;
  }
  .top-details .w-25 {
    width: auto !important;
  }

  /* add new visit */
  .addNew-form {
    width: 100%;
  }
  .add-new-visit {
    padding: 10px 15px;
  }
  .addNew-form .letter-wise {
    padding: 10px;
  }
  .addNew-form .letter-wise ul li a {
    font-size: 12px;
  }
  .add-new-header h1 {
    font-size: 15px;
  }
  .addNew-form .form-control {
    font-size: 13px;
  }
  .searchForm .searchBtn {
    font-size: 14px;
    height: 38px;
  }
  .complication-header {
    font-size: 15px;
  }
  .health-image {
    padding: 35px;
    padding-top: 30px;
  }
  .complication-list li h1 {
    font-size: 15px;
  }
  .complication-list li p {
    font-size: 13px;
  }

  /*  */
  /* Mobile header */
  .mob-header-wrap {
    left: 0px;
    right: auto;
  }
  .mob-wrap.d-none {
    display: block !important;
    padding-top: 20px;
  }
  .mob-notif-li {
    display: none !important;
  }
  .mob-header .mob-header-wrap {
    width: 100%;
    height: 20vh;
  }
  .static-header-wrap {
    position: absolute;
    top: 5px;
    right: 50px;
  }
  .mob-header .mob-header-wrap .header-menu-icon {
    width: 50px;
    height: 50px;
    background-size: 30px;
  }
  .mob-header .navbar-menu-sec {
    height: 40vh !important;
    margin-bottom: 10px;
  }
  .mob-header .mob-ul {
    position: relative;
    text-align: center;
    height: 20vh;
    padding-top: 20vh !important;
  }
  .mob-header .profile-li {
    position: absolute;
    bottom: -7px;
    left: 0;
    right: 0;
    position: absolute;
  }
  .mob-header .profile-li img {
    max-width: 70px !important;
  }
  .mob-navbar-nav {
    align-items: flex-start !important;
    padding-top: 30vh;
  }
  .mob-navbar-nav a {
    font-size: 16px !important;
  }
  .mob-header .navbar-toggler:not(.collapsed) .navbar-toggler-icon {
    background-image: url("../../images/noun_Close_1808841.png") !important;
    background-size: 15px;
  }
  .mob-navbar-nav .nav-item {
    width: 100%;
    border-bottom: 1px solid #eeeeee;
    padding: 5px 0px;
  }
  .mob-navbar-nav .nav-item.active::after {
    display: none !important;
  }
  .camera-icon {
    display: inline-block !important;
    position: absolute;
    width: 25px;
    bottom: 10px;
    right: 12px;
  }
  /* mobile header */
  .notify-dropdown {
    width: 260px !important;
  }
  .notify-dropdown .top-div span {
    margin-top: 10px;
  }
  .mob-header .navbar-menu-sec {
    height: 100vh !important;
    z-index: 9;
    background: #fff;
  }
  .mobile-covid-tracker .d-flex.space-between.align-items-center {
    position: relative;
    padding-bottom: 40px;
  }
  .mobile-covid-tracker .w-75 {
    width: 100% !important;
  }
  .mobile-covid-tracker .w-25 {
    position: absolute;
    right: 20px;
    bottom: 0px;
  }
  .qr-code-wrap .add-more-qr {
    border: 0;
    position: relative;
    display: block;
    padding: 0px;
  }
  .add-more-qr:before {
    display: none;
  }
  .add-more-qr button {
    position: relative;
    top: auto;
    left: auto;
    transform: none;
  }

  /*  */
  #addVisitModal .checkbox-check.w-49 {
    width: 100% !important;
  }
  .respiration .w-50,
  .pain .w-50 {
    width: 100% !important;
  }
  #addVisitModal.form-modal .form-inpit-div {
    padding-right: 0px !important;
  }
  .body-web {
    display: none;
  }
  .click-body-modal {
    display: block;
  }
  #addBodyModal .modal-body {
    height: auto;
  }

  /* event schedule */
  .add-meet {
    font-size: 12px;
  }
  .nested-tab-content .date-fld {
    width: 107px;
  }
  .dropdown-tab-heading.event {
    position: initial;
    top: auto;
    right: auto;
  }
  .event-main-ul li {
    width: 25%;
    font-size: 11px;
    padding: 20px 0px;
  }
  .event-main-ul li.time-li {
    width: 25% !important;
  }
  .events-list-main {
    padding-right: 0px;
  }
  .events-list-main li h6 {
    font-size: 14px;
  }
  .events-list-main li p {
    font-size: 11px;
  }
  .events-list-main li .view-btn {
    top: auto;
    bottom: -15px;
    padding: 6px 20px;
    font-size: 12px;
    right: 5px;
  }
  .events-list-main li .time-left p {
    left: 5px;
    /* top: 0; */
    transform: none;
  }
  .events-list-main li .time-left .time-start.time-above {
    top: 0;
  }
  .events-list-main li .time-right {
    width: 82%;
    padding: 10px 7px;
  }
  .events-list-main li .time-left {
    width: 18% !important;
  }
  .common-modal .modal-dialog,
  #addMeeting .modal-dialog,
  #dailyCatchupModal .modal-dialog {
    width: 100% !important;
  }
  .details-div p:nth-child(2) {
    padding: 0px !important;
  }
  .details-div p:nth-child(3) {
    padding-left: 0px !important;
  }
  #dailyCatchupModal .cathup-ul li {
    padding: 2px 13px;
  }
  #dailyCatchupModal .description-p {
    line-height: 20px !important;
    width: 100%;
  }
  #dailyCatchupModal .modal-title {
    font-size: 16px;
  }
  #dailyCatchupModal .details-div p {
    line-height: initial;
    display: inline-block;
  }
  #dailyCatchupModal .details-div span {
    font-size: 12px;
    /* margin: 0px !important; */
  }
  #dailyCatchupModal strong {
    font-size: 12px;
    font-weight: 650;
  }
  #addMeeting .form-check-label {
    font-size: 13px;
  }
  .end-date-div span {
    font-size: 13px;
  }
  .meetingModal .w-25 {
    width: 33% !important;
  }
  #addMeeting .modal-title {
    font-size: 18px;
  }
  .meetingModal .end-after {
    margin-left: 15%;
  }
  .end-date-div .form-inpit-div:nth-child(2) {
    width: 52% !important;
  }
  .chart-list li p {
    font-size: 14px;
  }
  .chart-list .w-75 {
    width: 100% !important;
  }
  .select-disease span {
    font-size: 11.5px;
    margin-right: 10px;
  }
}
.form-row label {
  font-size: 12px;
  margin: 0;
  color: #5d5d5d;
}
.form-row input {
  border-radius: 4px;
  cursor: auto;
}
.form-group {
  margin-bottom: 0.8rem;
}
@media only screen and (max-width: 1450px) {
  .close-a img {
    width: 15px;
    vertical-align: initial;
  }
  .close-a {
    display: block;
  }
  #main-form {
    padding: 18px 30px 30px 30px;
    top: 51.5%;
  }
  #main-form h2 {
    margin-bottom: 16px;
    font-size: 28px;
  }
  label {
    font-size: 10px;
    margin-bottom: 0.3rem;
  }
  .form-control {
    font-size: 12px;
  }
  .form-group {
    margin-bottom: 0.5rem;
  }
  #radio-div {
    margin-bottom: 1rem;
  }
  .submit-button {
    font-size: 12px;
  }
  #text-bottom {
    font-size: 12px;
  }
}
.dropdown-menu .dropdown-item  {
	font-size: 11px;
}

header .dropdown-menu.dropdown-menu-right.show {
  left: auto;
  right: 0;
}
header .dropdown-menu.dropdown-menu-right.show {
  left: auto;
  right: 0;
}