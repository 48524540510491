.dyForm{
    font-size:14px;
}
.dyForm .dyFilterForm p{
    font-size:15px;
}
.dyForm .dyFilterForm select{
    border:1px solid #ccc;
}
#createNewFormBtn{
    height: 30px;
    width: 140px;
    padding-top: 4px;
    background: #003246;
    font-size: 13px;
    font-family: helvetica;
    color: white;
    padding-bottom: 4px;
    margin-left:auto;
}
  .dyDropMain img {
      height: 14px;
      margin-bottom: 16px;
  }
   
  .more-btn.dropdown-toggle::after {
      display: none;
  }
  .dyListDropBtn{
      border:1px solid transparent;
      background: white;
      margin-bottom: auto;
  }
  #dyDropItem{
      top:-5px !important;
  }
  .dyList a{
      color:#043692;
      font-size: 15px;
      font-weight:bold;
      margin-right:10px;
  }
  .dyList .active{
      border:1px solid rgb(164, 238, 164);
      background: rgb(164, 238, 164);
      border-radius: 15px;
      padding:2px;
  }
  .dyList .inactive{
    border:1px solid rgb(218, 221, 218);
    background: rgb(218, 221, 218);
    border-radius: 15px;
    padding:3px;
}
.dyList{
    height:60vh;
    overflow-y:scroll ;
}
.dyList::-webkit-scrollbar {
    width:1px;
    height:0px;
    display: none;
  }

  .common-modal .datepicker {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAQZJREFUSEvtlb1Rw1AQhL8NILWoBFkN4EROMRXQAiVQgunAHRinOBENCLkCWpBTPONj9PwvjS3e8MaRLpPutPt292lO1CsZGmaffM0Hjd6lF/00Q3og/9Dx2MmDawQniAcRupki+Z24TZ5Zhq2eRH/4jnjEWICVTia2xCjaME76Igb1nL0oQtxjzOQsqWrnXSiLkrSsCJsELiwK8vmLl4IkHWPE+8uxDb1J4IV6YbhBEAq4hnNQ4MIJWNvQr2hR7Q/8t5brh9wpqIfWZdB6jTuLPCzaLAbWP3cUWdn64V8GNmv4G6FqZU4Qz5hV4H5r8jxZjBSBvQnHdjtGNnJKgpQtgQnr1esvylaWlVplX6QAAAAASUVORK5CYII=) white no-repeat right 0.75rem center/8px 10px;
    background-size: 13px;
    background-position-y: 12px;
    cursor: pointer;
    border: 1px solid #ccc;
    padding: 5px;
    align-items: center;
}