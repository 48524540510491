.message-container-bot {
    width: 'auto';
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: flex-start;
    align-items: center;
    border: 1px solid #9EB1E6;
    margin: 5px 38px 5px 7px;
    background-color: #9EB1E6;
    border-radius: 10px;
    box-shadow: inset;
    padding: 5px 5px;
}

.message-container-me {
    width: 'auto';
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: flex-start;
    align-items: center;
    border: 1px solid #F5F5F5;
    margin: 5px 7px 5px 38px;
    background-color: #F5F5F5;
    border-radius: 10px;
    box-shadow: inset;
    padding: 5px 5px;
}



.sender-box-bot {
    height: 30px;
    width: 30px;
    text-align: center;
    align-self: flex-start;
    padding: 4px 0px;
    border: 1px solid #e61616;
    background-color: #e61616;
    border-radius: 50%;
    box-shadow: inset;
    color: #c7cdda;
    margin-left: -20px;
}

.sender-box-me {
    height: 30px;
    width: 30px;
    text-align: center;
    align-self: flex-start;
    padding: 4px 0px;
    border: 1px solid #e61616;
    background-color: #e61616;
    border-radius: 50%;
    box-shadow: inset;
    color: #c7cdda;
    margin-right: -20px;
}
.message-box {
    height: fit-content;
    width: 80%;
    text-align: left;
}